import clsx from 'clsx';
import Autocomplete from 'components/common/Autocomplete';
import { Button } from 'components/shared/Button';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { Input } from 'components/shared/Input';
import MultiSelect from 'components/shared/MultiSelect';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from './StepTwo.module.scss';
import { StepTwoProps } from './StepTwo.props';
import { useStepTwo } from './useStepTwo';

export const StepTwo = React.forwardRef<ReCAPTCHA, StepTwoProps>(
  (
    {
      recaptcha,
      setRecaptcha,
      control,
      errors,
      isValid,
      selectedProducts,
      setSelectedProducts,
      productsOptions = [],
      onMoreProducts,
      isLoadingProducts,
      selectedLocation,
      setSelectedLocation,
      locationOptions = [],
      isStepTwoValid,
      setIsStepTwoValid,
    },
    recaptchaRef
  ) => {
    const { callbacks } = useStepTwo(
      productsOptions,
      setSelectedProducts,
      locationOptions,
      setSelectedLocation,
      isValid,
      recaptcha,
      selectedProducts,
      setIsStepTwoValid,
      selectedLocation
    );
    const { t } = useTranslation();

    return (
      <>
        <div className={classes['top-fields']}>
          <div className={classes['input-field']}>
            <div className={classes['item-label']}>{t('Company name')}</div>
            <Controller
              render={({ field }) => (
                <Input
                  maxLength={200}
                  type={'text'}
                  placeholder={t('Enter company name')}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors?.companyName}
                  className={classes.register_theme}
                />
              )}
              control={control}
              name="companyName"
            />
            {errors?.companyName && (
              <TooltipContainer
                text={errors?.companyName?.message}
                customClasses={'kit-ui-block'}
                position={'top'}
                className={classes.error}
              >
                <InfoIcon fill="#F5222D" width="18px" height="18px" />
              </TooltipContainer>
            )}
          </div>

          <div className={classes['input-field']}>
            <div className={classes['item-label']}>{t('Company website')}</div>
            <Controller
              control={control}
              render={({ field }) => (
                <Input
                  maxLength={50}
                  type={'text'}
                  placeholder={t('Website, LinkedIn, Fb, etc.')}
                  className={clsx(classes.email, classes.register_theme)}
                  value={field.value}
                  error={!!errors?.companyUrl}
                  onChange={field.onChange}
                />
              )}
              name="companyUrl"
            />
            {errors?.companyUrl && (
              <TooltipContainer
                text={errors.companyUrl?.message}
                customClasses={'kit-ui-block'}
                position={'top'}
                className={classes.error}
              >
                <InfoIcon fill="#F5222D" width="18px" height="18px" />
              </TooltipContainer>
            )}
          </div>

          <div className={classes['input-field']}>
            <div className={classes['item-label']}>{t('Job title')}</div>
            <Controller
              render={({ field }) => (
                <Input
                  maxLength={254}
                  type={'text'}
                  placeholder={t('What is your job title?')}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors?.jobTitle}
                  className={classes.register_theme}
                />
              )}
              control={control}
              name="jobTitle"
            />
            {errors?.jobTitle && (
              <TooltipContainer
                text={errors?.jobTitle?.message}
                customClasses={'kit-ui-block'}
                position={'top'}
                className={classes.error}
              >
                <InfoIcon fill="#F5222D" width="18px" height="18px" />
              </TooltipContainer>
            )}
          </div>

          <div className={clsx(classes['input-field'])}>
            <div className={classes['item-label']}>{t('Office Location')}</div>
            <Controller
              name="location"
              control={control}
              render={() => (
                <Autocomplete
                  options={locationOptions}
                  selected={
                    selectedLocation && {
                      value: selectedLocation.id,
                      label: selectedLocation.name,
                    }
                  }
                  onSelect={(id) => callbacks.onSelectLocation(Number(id))}
                  placeholder={t('Enter your country')}
                  additionalClass={classes.register_theme}
                />
              )}
            />
            {errors?.location && (
              <TooltipContainer
                text={errors?.location?.message}
                customClasses={'kit-ui-block'}
                position={'top'}
                className={classes.error}
              >
                <InfoIcon fill="#F5222D" width="18px" height="18px" />
              </TooltipContainer>
            )}
          </div>
        </div>

        <div
          className={clsx(classes['input-field'], classes['input-field--full'])}
        >
          <div className={classes['item-label']}>{t('Company products')}</div>
          <Controller
            name="userProducts"
            control={control}
            render={() => (
              <MultiSelect
                isLoading={isLoadingProducts}
                options={productsOptions}
                scrollDebounce={300}
                onScrollBottom={onMoreProducts}
                selected={selectedProducts.map((product) => ({
                  value: product.id,
                  label: product.name,
                }))}
                onSelect={(id) => callbacks.onSelectProduct(Number(id))}
                onDeleteSelected={(id) =>
                  callbacks.onDeleteSelectedProduct(Number(id))
                }
                placeholder={t('What are your company products?')}
                additionalClassName={classes.multiselect}
              />
            )}
          />
          {errors?.userProducts && (
            <TooltipContainer
              text={errors?.userProducts?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>

        <div className={classes.captcha}>
          <Controller
            render={({ field }) => (
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTHA_KEY}`}
                onChange={(e: any) => {
                  field.onChange(e);
                  e && setRecaptcha(e);
                }}
                hl="en"
                size="normal"
              />
            )}
            name="captcha"
            control={control}
          />
        </div>

        <div className={classes.submit}>
          <Button
            type="submit"
            className={clsx(classes['submit-button'])}
            theme="primary"
            disabled={!isStepTwoValid}
          >
            {t('Create account')}
          </Button>
        </div>
      </>
    );
  }
);

StepTwo.displayName = 'StepTwo';
