import { observer } from 'mobx-react-lite';
import { Controller, FormProvider } from 'react-hook-form';
import Modals from '../Modals';
import { initialSteps, materialsData } from '../utils';
import FirsStep from './FirstStep';
import classes from './FormBlock.module.scss';
import { FormBlockProps } from './FormBlock.props';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import { useFormBlock } from './useFormBlock';

export const FormBlock = observer(
  ({
    setCurrentStep,
    currentStep,
    copiedService,
    isDisabled,
  }: FormBlockProps) => {
    const {
      methods,
      productTypes,
      industryTypes,
      dictionaries,
      companySizes,
      internalData,
      goals,
      setOpenCancelModal,
      platforms,
      setOpenSubmitModal,
      openCancelModal,
      openSubmitModal,
      handleSubmit,
      handleCancel,
      t,
    } = useFormBlock(copiedService, currentStep);

    return (
      <div className={classes.formBlock}>
        <FormProvider {...methods}>
          <form className={classes.form}>
            {currentStep === 1 && (
              <FirsStep
                productTypes={productTypes}
                industryTypes={industryTypes}
                companySizes={companySizes(dictionaries)}
                initialSteps={internalData?.stages || initialSteps(t)}
                defaultGoals={goals}
                setCurrentStep={setCurrentStep}
                setOpenCancelModal={setOpenCancelModal}
                materialsData={materialsData(t)}
                copiedService={internalData}
                disabled={isDisabled}
              />
            )}

            {currentStep === 2 && (
              <Controller
                render={({ field }) => (
                  <SecondStep
                    setCurrentStep={setCurrentStep}
                    setOpenCancelModal={setOpenCancelModal}
                    platforms={platforms}
                    disabled={isDisabled}
                    defaultValues={field.value}
                  />
                )}
                name="cases"
                control={methods.control}
              />
            )}

            {currentStep === 3 && (
              <ThirdStep
                setCurrentStep={setCurrentStep}
                setOpenSubmitModal={setOpenSubmitModal}
                setOpenCancelModal={setOpenCancelModal}
                disabled={isDisabled}
              />
            )}
          </form>
        </FormProvider>

        <Modals
          openCancelModal={openCancelModal}
          setOpenCancelModal={setOpenCancelModal}
          openSubmitModal={openSubmitModal}
          setOpenSubmitModal={setOpenSubmitModal}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          currentStep={currentStep}
        />
      </div>
    );
  }
);
