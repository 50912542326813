import { MultiSelectOption } from 'components/shared/MultiSelect';
import { LABELS } from 'constants/onboarding';
import { useCommonStore } from 'contexts/CommonContext';
import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isOnboardingActive, setOnboardingOptions } from 'tools/onboarding';
import { getLangName } from 'tools/utils';
import { onDelete, onSelect } from '../../utils';
import classes from '../FormBlock.module.scss';
import { FormValidationProps } from '../FormBlock.props';

export const useThirdStep = (
  setOpenSubmitModal: (val: boolean) => void,
  disabled?: boolean
) => {
  const { t, i18n } = useTranslation();
  const isFree = useWatch({ name: 'is_free' });
  const defaultCountries = useWatch({ name: 'countries' });
  const defaultPaymentTypes = useWatch({ name: 'payment_type' });

  const [selectedCountries, setSelectedCountries] = useState(
    defaultCountries || []
  );
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState(
    defaultPaymentTypes || []
  );
  const [internalCountryOptions, setInternalCountryOptions] = useState<
    MultiSelectOption[]
  >([]);
  const [internalPaymentOptions, setInternalPaymentOptions] = useState<
    MultiSelectOption[]
  >([]);

  const userStore = useUserStore();
  const { isMediaTablet, isSmallLaptop: isLaptop } = useWindowWidth();

  const { countries, dictionaries } = useCommonStore() || {};
  const { payment_types } = dictionaries || {};
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    getValues,
  } = useFormContext<FormValidationProps>();

  const enableHints =
    isOnboardingActive(userStore?.pagesToOnboard?.solutionPrice) && !disabled;
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const handleExit = useCallback(() => {
    if (!disabled) {
      setOnboardingOptions({ solutionPrice: true });
      userStore.enableOnboarding(true);
    }
  }, [userStore.enableOnboarding]);

  useEffect(() => {
    defaultCountries && setSelectedCountries(defaultCountries);
    defaultPaymentTypes && setSelectedPaymentTypes(defaultPaymentTypes);
  }, [defaultCountries, defaultPaymentTypes]);

  const countriesOptions = useMemo(
    () =>
      countries?.map((item) => ({
        value: item.id,
        label: getLangName(item, 'name', i18n),
      })),
    [countries]
  );

  const paymentTypeOptions = useMemo(
    () =>
      payment_types?.map((item) => ({
        value: item.id,
        label: getLangName(item, 'name', i18n),
      })),
    [payment_types]
  );

  useEffect(() => {
    paymentTypeOptions && setInternalPaymentOptions(paymentTypeOptions);
  }, [paymentTypeOptions]);

  useEffect(() => {
    setInternalCountryOptions(countriesOptions);
  }, [countriesOptions]);

  useEffect(() => {
    if (isFree) setValue('price', '0');
  }, [isFree]);

  const onValidateClick = async () => {
    if (trigger) {
      const isValid = await trigger();
      if (isValid) {
        setOpenSubmitModal(true);
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  const endIconOptions = (text: string) => ({
    endIconName: 'HelpIcon',
    endIconTooltipText: text,
    endIconTooltipPosition: isMediaTablet ? 'top' : 'right',
    endIconTooltipClassName: classes.endIconTooltip,
    endIconClassName: classes.endIcon,
  });

  const callbacks = {
    onSelectCountry: (id: number | string, onChange: any) =>
      onSelect(id, countriesOptions, setSelectedCountries, onChange),
    onSelectPaymentType: (id: number | string, onChange: any) =>
      onSelect(id, paymentTypeOptions, setSelectedPaymentTypes, onChange),
    onDeleteCountry: (id: number | string, onChange: any) =>
      onDelete(id, setSelectedCountries, onChange),
    onDeletePaymentType: (id: number | string, onChange: any) =>
      onDelete(id, setSelectedPaymentTypes, onChange),
  };

  return {
    isLaptop,
    enableHints,
    t,
    GOT_IT,
    NEXT,
    BACK,
    handleExit,
    isFree,
    errors,
    endIconOptions,
    control,
    internalPaymentOptions,
    selectedPaymentTypes,
    isMediaTablet,
    callbacks,
    internalCountryOptions,
    selectedCountries,
    paymentTypeOptions,
    setInternalPaymentOptions,
    countriesOptions,
    setInternalCountryOptions,
    onValidateClick,
  };
};
