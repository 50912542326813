import clsx from 'classnames';
import MultiSelect from 'components/shared/MultiSelect';
import { SelectData } from 'components/shared/SelectComponent';
import {
  addSolutionsSecondStepHintsSecond,
  LABELS,
} from 'constants/onboarding';
import { useMarketPlaceStore } from 'contexts/marketPlaceContext';
import { Steps } from 'intro.js-react';
import { Controller } from 'react-hook-form';
import Input from '../Input';
import { Textarea } from '../Input/TextArea';
import { Select } from '../Select';
import { multiFieldValues } from '../utils';
import classes from './AddCaseForm.module.scss';
import { AddCaseFormProps } from './AddCaseForm.props';
import { KPIFormContainer } from './KPIForm';
import { useAddCaseForm } from './useAddCaseForm';

export const AddCaseForm = ({
  caseItem,
  disabled,
  caseNumber = 1,
  onRemoveCase,
  platforms,
}: AddCaseFormProps) => {
  const { businessTypes } = useMarketPlaceStore() || {};
  const {
    isLaptop,
    enableHints,
    t,
    handleExit,
    generalCount,
    endIconOptions,
    errors,
    control,
    setSelectBusinessTypes,
    selectPlatforms,
    callbacks,
    formattedValue,
    internalPlatforms,
  } = useAddCaseForm(caseNumber, disabled, platforms);
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  return (
    <div className={classes.caseWrapper}>
      <Steps
        enabled={isLaptop && enableHints}
        steps={addSolutionsSecondStepHintsSecond(t)}
        options={{
          tooltipClass: clsx('customTooltip', classes.hintsTooltip),
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={handleExit}
        onExit={handleExit}
      />

      <div className={clsx(classes.hint, classes['hint--1'])}>
        <div className={clsx(classes.title)}>
          <div className={classes.title__withCounter}>
            <span>{`${t('Case')} ${caseNumber + 1}`}</span>
            <span className={classes.counter}>
              {generalCount} {t('out of 5 filled')}
            </span>
          </div>
          <button
            disabled={disabled}
            className={clsx(classes.deleteButton, disabled && classes.disabled)}
            onClick={onRemoveCase}
          >
            {t('Remove')}
          </button>
        </div>

        <Controller
          render={({ field }) => (
            <Input
              placeholder={t('Name your case')}
              classNames={classes.input}
              onChange={(e: any) => {
                field.onChange(e);
              }}
              endIcon={endIconOptions(
                t(
                  'Enter the case title. This helps identify what the case is about'
                )
              )}
              maxLength={50}
              value={field.value}
              title={t('Name your case')}
              error={errors?.cases?.[caseNumber]?.caseName?.message as string}
              errorText={errors?.cases?.[caseNumber]?.caseName?.message}
            />
          )}
          name={`cases.${caseNumber}.caseName`}
          control={control}
        />

        <Controller
          render={({ field }) => (
            <Select
              title={t('Business type')}
              placeholder={t('Select category')}
              endIcon={endIconOptions(
                t(
                  'Indicate the business type. This helps potential clients understand the industry of the case.'
                )
              )}
              className={classes.select}
              select={businessTypes?.find(
                (item: any) => item?.id === field?.value
              )}
              options={businessTypes}
              defaultValue={caseItem && { name: field.value }}
              setSelect={setSelectBusinessTypes}
              error={errors?.cases?.[caseNumber]?.caseBusinessType?.message}
              onSelect={(item: SelectData) => {
                field.onChange(item?.id);
              }}
              isSolutionForm
            />
          )}
          name={`cases.${caseNumber}.caseBusinessType`}
          control={control}
        />
      </div>

      <div className={clsx(classes.hint, classes['hint--2'])}>
        <Controller
          render={({ field }) => (
            <Input
              placeholder={t('Key achievement')}
              classNames={classes.input}
              onChange={(e: any) => {
                field.onChange(e);
              }}
              endIcon={endIconOptions(
                t(
                  'Highlight the key achievement. This shows how similar problems were successfully resolved'
                )
              )}
              value={field.value}
              maxLength={50}
              title={t('Key achievement')}
              error={errors?.cases?.[caseNumber]?.keyAchievements?.message}
              errorText={errors?.cases?.[caseNumber]?.keyAchievements?.message}
            />
          )}
          name={`cases.${caseNumber}.keyAchievements`}
          control={control}
        />

        <Controller
          render={({ field }) => (
            <MultiSelect
              title={t('Platforms')}
              isSolutionForm
              endIcon={endIconOptions(
                t(
                  'List the platforms used in the case. This shows the tools and technologies involved'
                )
              )}
              additionalClassName={classes.multiselectWrapper}
              options={internalPlatforms}
              selected={multiFieldValues(selectPlatforms, field, true)}
              onSelect={(id) => {
                callbacks.onSelectProduct(id, field.onChange);
              }}
              onDeleteSelected={(id) => {
                callbacks.onDeleteSelectedProduct(id, field.onChange);
                selectPlatforms.length === 1 && field.onChange(null);
              }}
              placeholder=""
              error={errors?.cases?.[caseNumber]?.platforms?.message as string}
            />
          )}
          name={`cases.${caseNumber}.platforms`}
          control={control}
        />

        <Controller
          render={({ field }) => (
            <Textarea
              placeholder={t('Description')}
              value={formattedValue(field.value)}
              onChange={(e: any) => {
                field.onChange(e);
              }}
              endIcon={endIconOptions(
                t(
                  'Provide a detailed description of the case. This helps clients understand the scope and details of the project'
                )
              )}
              title={t('Description')}
              errorText={
                errors?.cases?.[caseNumber]?.secondStepDescription?.message
              }
              error={
                errors?.cases?.[caseNumber]?.secondStepDescription?.message
              }
              errorClassName={
                !!errors?.cases?.[caseNumber]?.secondStepDescription &&
                classes.errorTextArea
              }
            />
          )}
          name={`cases.${caseNumber}.secondStepDescription`}
          control={control}
        />

        <KPIFormContainer
          caseNumber={caseNumber}
          initialSteps={caseItem?.kpi}
        />
      </div>
    </div>
  );
};
