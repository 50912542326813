import { SelectData } from 'components/shared/SelectComponent';
import { htmlTagPattern } from 'constants/matches';
import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isOnboardingActive, setOnboardingOptions } from 'tools/onboarding';
import formClasses from '../FormBlock/FormBlock.module.scss';
import { onDelete, onSelect } from '../utils';
import { FormValidationProps } from '../FormBlock/FormBlock.props';
import { MultiSelectProps } from '../FormBlock/FirstStep/FirsStep.props';
import { MultiSelectOption } from '../../../components/shared/MultiSelect';

export const useAddCaseForm = (
  caseNumber?: number,
  disabled?: boolean,
  platforms?: any
) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValidationProps>();
  const { t } = useTranslation();
  const userStore = useUserStore();
  const { isMediaTablet, isSmallLaptop: isLaptop } = useWindowWidth();

  const caseNameValue = useWatch({ name: `cases.${caseNumber}.caseName` });
  const caseBusinessTypeValue = useWatch({
    name: `cases.${caseNumber}.caseBusinessType`,
  });
  const keyAchievementsValue = useWatch({
    name: `cases.${caseNumber}.keyAchievements`,
  });
  const platformsValue = useWatch({ name: `cases.${caseNumber}.platforms` });
  const secondStepDescriptionValue = useWatch({
    name: `cases.${caseNumber}.secondStepDescription`,
  });

  const enableHints =
    isOnboardingActive(userStore?.pagesToOnboard?.solutionCasesForm) &&
    !disabled;

  const handleExit = useCallback(() => {
    if (!disabled) {
      setOnboardingOptions({ solutionCasesForm: true });
      userStore.enableOnboarding(true);
    }
  }, [userStore.enableOnboarding]);

  const [generalCount, setGeneralCount] = useState<number>(0);
  const [selectBusinessType, setSelectBusinessTypes] =
    useState<SelectData | null>(null);
  const [selectPlatforms, setSelectPlatforms] = useState<MultiSelectProps[]>(
    platformsValue || []
  );
  const [internalPlatforms, setInternalPlatforms] = useState<
    MultiSelectOption[]
  >([]);

  useEffect(() => {
    setGeneralCount(
      [
        caseNameValue,
        caseBusinessTypeValue,
        keyAchievementsValue,
        platformsValue?.length,
        secondStepDescriptionValue,
      ].filter((item: any) => item).length
    );
  }, [
    caseNameValue,
    caseBusinessTypeValue,
    keyAchievementsValue,
    platformsValue,
    secondStepDescriptionValue,
  ]);

  useEffect(() => {
    platforms && setInternalPlatforms(platforms);
  }, [platforms]);

  const formattedValue = (value: string) =>
    value && htmlTagPattern.test(value)
      ? value?.replace(/<\/?[^>]+>/gi, '')
      : value;

  const callbacks = {
    onSelectProduct: (id: number | string, onChange: any) =>
      onSelect(id, platforms, setSelectPlatforms, onChange),
    onDeleteSelectedProduct: (id: number | string, onChange: any) =>
      onDelete(id, setSelectPlatforms, onChange),
  };

  const endIconOptions = (title: string) => ({
    endIconName: 'HelpIcon',
    endIconTooltipText: title,
    endIconTooltipPosition: isMediaTablet ? 'top' : 'right',
    endIconTooltipClassName: formClasses.endIconTooltip,
    endIconClassName: formClasses.endIcon,
  });

  return {
    isLaptop,
    enableHints,
    t,
    handleExit,
    generalCount,
    endIconOptions,
    errors,
    control,
    setSelectBusinessTypes,
    selectPlatforms,
    callbacks,
    formattedValue,
    internalPlatforms,
  };
};
