import React, { FC, useEffect, useRef } from 'react';
import classes from './UploadMaterials.module.scss';
import UiIcon from '../../../components/shared/Icon';
import { Button } from '../../../components/shared/Button';
import { useTranslation } from 'react-i18next';
import clsx from 'classnames';
import { EndIconProps } from '../Input';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';

interface UploadMaterialsProps {
  data?: any;
  error?: string;
  hideDeleteButtons?: boolean;
  infoIconOptions?: EndIconProps;
  onRemoveFile?: () => void;
  onUploadFile?: (file: File) => void;
}

const UploadMaterials: FC<UploadMaterialsProps> = ({
  data,
  onRemoveFile,
  error,
  onUploadFile,
  hideDeleteButtons,
  infoIconOptions,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = React.useState<string>(data?.name || '');
  const [size, setSize] = React.useState<number>(data?.size || 0);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const {
    endIconName: infoIconName,
    endIconTooltipText: infoIconTooltipText,
    endIconTooltipPosition: infoIconTooltipPosition,
    endIconTooltipClassName: infoIconTooltipClassName,
    endIconClassName: infoIconClassName,
  } = infoIconOptions || {};

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const file = fileInput.files?.[0];

    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        alert(t('File size exceeds 10 MB'));
        fileInput.value = '';
        return;
      }
      if (
        ![
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ].includes(file.type)
      ) {
        alert(t('Invalid file type'));
        fileInput.value = '';
        return;
      }
      setSelectedFile(file);
      onUploadFile && onUploadFile(file);
    }

    fileInput.value = '';
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (selectedFile || data?.name) {
      const shortFileName =
        (selectedFile || data).name?.length > 15
          ? `${(selectedFile || data).name?.slice(0, 15)}...`
          : (selectedFile || data).name;
      setName(shortFileName);
      setSize(selectedFile?.size || data?.size);
    } else {
      setSize(0);
      setName('');
    }
  }, [selectedFile]);

  return (
    <div className={classes.materials}>
      <div className={classes.materials__item}>
        <div className={classes.materials__text}>
          <div className={classes.materials__text__title}>
            <span>{t('Project brief')}</span>
            {infoIconName && (
              <TooltipContainer
                text={infoIconTooltipText}
                customClasses={'kit-ui-block'}
                position={infoIconTooltipPosition}
                className={clsx(
                  infoIconTooltipClassName,
                  classes['end-icon__tooltip']
                )}
              >
                <UiIcon
                  name={infoIconName}
                  additionalClassName={clsx(
                    classes.infoIcon,
                    infoIconClassName,
                    error && classes.errorIcon
                  )}
                />
              </TooltipContainer>
            )}
          </div>
          <span className={classes.materials__text__description}>
            {t('Maximum file size 10 Mb (pdf, doc, docx)')}
          </span>
        </div>
        <div className={classes.materials__upload}>
          <Button
            className={clsx(
              classes.materials__upload__button,
              error && classes.error
            )}
            theme="light"
            onClick={handleButtonClick}
          >
            <UiIcon
              name="PaperClipIcon"
              additionalClassName={classes.paperClipIcon}
            />
            <div className={classes.materials__upload__fileName}>
              <span>{name || t('Add brief')}</span>
              {error && <div className={classes.errorText}>{error}</div>}
              {!error && (
                <span className={classes.materials__upload__fileName__size}>
                  ~{(size / 1024 / 1024).toFixed(2)} {t('Mb')}
                </span>
              )}
            </div>
          </Button>
          {!hideDeleteButtons && data?.name && (
            <UiIcon
              name="RemoveIcon"
              additionalClassName={classes.removeIcon}
              onClick={() => {
                onRemoveFile && onRemoveFile();
                setSelectedFile(null);
                setName('');
              }}
            />
          )}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx"
          />
        </div>
      </div>
    </div>
  );
};

export default UploadMaterials;
