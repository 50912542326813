import clsx from 'classnames';
import { StepsContainer } from 'components/common/StepsContainer';
import { Button } from 'components/shared/Button';
import MultiSelect from 'components/shared/MultiSelect';
import { SelectData } from 'components/shared/SelectComponent';
import { addSolutionsFirstStepHints, LABELS } from 'constants/onboarding';
import { ProjectGoalsContainer } from 'containers/ProjectsDetail/Goals';
import { useCommonStore } from 'contexts/CommonContext';
import { Steps } from 'intro.js-react';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../Input';
import { Textarea } from '../../Input/TextArea';
import { Select } from '../../Select';
import UploadMaterials from '../../UploadMaterials';
import { multiFieldValues } from '../../utils';
import classes from '../FormBlock.module.scss';
import { FirsStepProps } from './FirsStep.props';
import { useFirstStep } from './useFirstStep';

const FirsStep = observer(
  ({
    productTypes,
    industryTypes,
    companySizes,
    initialSteps,
    setCurrentStep,
    setOpenCancelModal,
    materialsData,
    disabled,
  }: FirsStepProps) => {
    const { t } = useTranslation();
    const {
      isLaptop,
      enableHints,
      handleExit,
      generalCount,
      endIconOptions,
      errors,
      control,
      internalIndustries,
      isMediaTablet,
      selectIndustryTypes,
      callbacks,
      setInternalIndustries,
      selectedCompanySizes,
      internalCompanySizes,
      setInternalCompanySizes,
      detailCount,
      materialCount,
      onValidateClick,
      file,
      setMaterialCount,
    } = useFirstStep(companySizes, industryTypes, setCurrentStep, disabled);
    const { dictionaries } = useCommonStore();
    const { GOT_IT, NEXT, BACK } = LABELS(t);
    const blockId = 'general_block';

    return (
      <>
        <Steps
          enabled={isLaptop && enableHints}
          steps={addSolutionsFirstStepHints(t)}
          options={{
            tooltipClass: clsx('customTooltip', classes.hintsTooltip),
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={0}
          onComplete={handleExit}
          onExit={handleExit}
        />
<div className={classes.generalGoalsWrapper}>
        <div
          className={clsx(classes.generalBlock, disabled && classes.disabled)}
          id={blockId}
        >
          <div className={clsx(classes.title, classes.title__withCounter)}>
            <span>{t('General overview')}</span>
            <span className={classes.counter}>
              {generalCount} {t('out of 5 filled')}
            </span>
          </div>

          <Controller
            render={({ field }) => (
              <Input
                placeholder={t('Solution name')}
                classNames={classes.solutionInput}
                onChange={(e: any) => {
                  field.onChange(e);
                }}
                maxLength={50}
                value={field.value}
                endIcon={endIconOptions(
                  t(
                    'Please enter a name for your solution (up to 50 characters). This helps to identify your solution.'
                  )
                )}
                title={t('Solution name')}
                error={errors.name?.message}
                errorText={errors.name?.message}
              />
            )}
            name="name"
            control={control}
          />

          <Controller
            render={({ field }) => (
              <Select
                title={t('Product type')}
                placeholder={t('Select category')}
                select={productTypes?.find(
                  (item: any) => item?.id === field?.value
                )}
                options={productTypes}
                isSolutionForm
                endIcon={endIconOptions(
                  t(
                    'Choose a product type. It helps categorize your solution better.'
                  )
                )}
                onSelect={(item: SelectData) => {
                  field.onChange(item?.id);
                }}
                error={errors?.productType?.message}
              />
            )}
            name="productType"
            control={control}
          />

          <Controller
            render={({ field }) => (
              <MultiSelect
                title={t('Company industry')}
                additionalClassName={classes.multiselectWrapper}
                options={internalIndustries}
                isSolutionForm
                multiline
                contentHeight="50px"
                contentWidth={isMediaTablet ? '103%' : 'unset'}
                showCounter
                isSearch
                withOrder
                searchPlaceholder={t('Search')}
                endIcon={endIconOptions(
                  t(
                    'Let us know the industries relevant to your solution. It helps provide context.'
                  )
                )}
                selected={multiFieldValues(selectIndustryTypes, field)}
                onSelect={(id) => {
                  callbacks.onSelectIndustry(id, field.onChange);
                }}
                onDeleteSelected={(id) => {
                  if (id === 'all') field.onChange(null);
                  callbacks.onDeleteSelectedIndustry(id, field.onChange);
                  selectIndustryTypes.length === 1 && field.onChange(null);
                }}
                onSearch={(value) => {
                  industryTypes &&
                    setInternalIndustries(
                      industryTypes?.filter((item) =>
                        String(item?.label)
                          ?.toLowerCase()
                          ?.includes(value.toLowerCase())
                      )
                    );
                }}
                error={errors?.industry?.message as string}
                placeholder=""
              />
            )}
            name="industry"
            control={control}
          />

          <Controller
            render={({ field }) => (
              <MultiSelect
                selected={multiFieldValues(selectedCompanySizes, field, true)}
                options={internalCompanySizes}
                isSolutionForm
                title={t('Company size')}
                placeholder=""
                isSearch
                multiline
                contentHeight="50px"
                contentWidth={isMediaTablet ? '103%' : 'unset'}
                showCounter
                searchPlaceholder={t('Search')}
                endIcon={endIconOptions(
                  t(
                    "Tell us the size of the companies you're targeting. This is crucial for planning and scalability."
                  )
                )}
                additionalClassName={classes.multiselectWrapper}
                onSelect={(id) => {
                  callbacks.onSelectCompanySizes(id, field.onChange);
                }}
                onDeleteSelected={(id) => {
                  if (id === 'all') field.onChange(null);
                  callbacks.onDeleteCompanySize(id, field.onChange);
                  companySizes.length === 1 && field.onChange(null);
                  selectedCompanySizes.length === 1 && field.onChange(null);
                }}
                onSearch={(value) => {
                  companySizes &&
                    setInternalCompanySizes(
                      companySizes?.filter((item) =>
                        String(item?.label)
                          ?.toLowerCase()
                          ?.includes(value.toLowerCase())
                      )
                    );
                }}
                error={errors?.companySize?.message}
              />
            )}
            name="companySize"
            control={control}
          />
        </div>

        <div className={clsx(classes.goalsBlock, disabled && classes.disabled)}>
          <Controller
            render={({ field }) => {
              return (
                <ProjectGoalsContainer
                  isAddSolution
                  systemGoals={dictionaries?.goals}
                  userGoals={field.value}
                  title={t('Expected results')}
                  tabsId={blockId}
                  error={!!errors?.goals}
                  onChange={field.onChange}
                  endIcon={endIconOptions(
                    t(
                      'Describe what you expect to deliver. Clear expectations keep everyone on the same page.'
                    )
                  )}
                />
              );
            }}
            name="goals"
            control={control}
          />
        </div>
        </div>
        <div className={classes.detailedStepsWrapper}>
        <div 
          className={clsx(classes.detailedBlock, disabled && classes.disabled)}
        >
          <div className={clsx(classes.title, classes.title__withCounter)}>
            <span>{t('Detailed description')}</span>
            <span className={classes.counter}>
              {detailCount} {t('out of 4 filled')}
            </span>
          </div>

          <Controller
            render={({ field }) => (
              <Input
                placeholder={t('Short description')}
                classNames={classes.input}
                endIcon={endIconOptions(
                  t(
                    'Provide a short description (up to 150 characters). It should give a quick overview of the solution.'
                  )
                )}
                title={t('Short description')}
                value={field.value}
                maxLength={150}
                onChange={(e: any) => {
                  field.onChange(e);
                }}
                error={errors.shortDescription?.message}
                // errorText={errors.shortDescription?.message}
              />
            )}
            name="shortDescription"
            control={control}
          />

          <Controller
            render={({ field }) => (
              <Textarea
                placeholder={t('Full description')}
                title={t('Full description')}
                value={field.value}
                onChange={(e: any) => {
                  field.onChange(e);
                }}
                maxLength={32000}
                endIcon={endIconOptions(
                  t(
                    'Give a detailed description of the project (up to 32000 characters). This will help your customers fully understand it.'
                  )
                )}
                additionalClassName={classes.textarea}
                // errorText={errors.fullDescription?.message}
                error={errors.fullDescription?.message}
                errorClassName={
                  !!errors.fullDescription && classes.errorTextArea
                }
              />
            )}
            name="fullDescription"
            control={control}
          />

          <Controller
            render={({ field }) => (
              <Input
                endIcon={endIconOptions(
                  t(
                    'How long will the project take? Specify in months, weeks, or days (up to 50 characters). This is important for planning.'
                  )
                )}
                title={t('Project duration')}
                placeholder={t('Project duration')}
                classNames={classes.input}
                value={field.value}
                onChange={(e: any) => {
                  field.onChange(e);
                }}
                maxLength={50}
                error={errors.projectDuration?.message}
                // errorText={errors.projectDuration?.message}
              />
            )}
            name="projectDuration"
            control={control}
          />
        </div>

        <div className={clsx(classes.stepsBlock, disabled && classes.disabled)}>
          <Controller
            render={({ field }) => {
              return (
                <StepsContainer
                  disableDelete={disabled}
                  infoIconOptions={endIconOptions(
                    t(
                      'Outline at least 1 stage of your project. It helps in planning, tracking, and visibility for the customer.'
                    )
                  )}
                  endIconOptions={endIconOptions(
                    t('Enter your Project stage. Up to 50 symbols')
                  )}
                  error={t(
                    'The solution must contain at least 1 stage. This field cannot be empty'
                  )}
                  title={t('Project stages')}
                  initialSteps={initialSteps}
                  defaultValues={field.value}
                />
              );
            }}
            name="steps"
            control={control}
          />
        </div>
        </div>
        <div
          className={clsx(classes.materialsBlock, disabled && classes.disabled)}
        >
          <div className={clsx(classes.title, classes.title__withCounter)}>
            <span>{t('Marketing materials')}</span>
            <span className={classes.counter}>
              {materialCount} {t('out of 1 filled')}
            </span>
          </div>

          {materialsData?.map((item: any, index: number) => (
            <Controller
              key={index}
              render={({ field }) => (
                <UploadMaterials
                  error={errors?.['useCases-2']?.message as any}
                  infoIconOptions={endIconOptions(
                    t(
                      "Upload a file with a detailed description of your project. This helps potential clients understand what you're offering."
                    )
                  )}
                  data={field.value}
                  onUploadFile={(file: File) => {
                    field.onChange(file);
                    setMaterialCount([file].length);
                  }}
                  onRemoveFile={() => {
                    if (file?.name) {
                      field.onChange(null);
                      setMaterialCount(materialCount - 1);
                    }
                  }}
                  hideDeleteButtons={disabled}
                />
              )}
              name="useCases-2"
              control={control}
            />
          ))}
        </div>

        {!disabled && (
          <div className={classes.bottomBlock}>
            <Button
              className={classes.button}
              theme="light"
              onClick={(e: any) => {
                e.preventDefault();
                setOpenCancelModal(true);
              }}
            >
              {t('Cancel')}
            </Button>

            <Button
              className={classes.button}
              theme="default"
              onClick={async (e: any) => {
                e.preventDefault();
                await onValidateClick();
              }}
            >
              {t('Continue')}
            </Button>
          </div>
        )}
      </>
    );
  }
);

export default FirsStep;
