import { FileIcon } from 'components/shared/Icons/FileIcon';
import classes from './ProjectDocuments.module.scss';
import { TFunction } from 'i18next';
import { handleDownload } from 'tools/fileDownload';
import { TProject } from '@type/partner';

type ProjectDocumentsProps = {
  t: TFunction;
  project: TProject | null;
};

function ProjectDocuments({ project, t }: ProjectDocumentsProps) {
  return (
    <ul className={classes['documents-list']}>
      {project?.files.map((doc, index) => {
        return (
          <li
            key={index + doc.file + doc.size}
            className={classes['documents-list__item']}
          >
            <div className={classes['documents-list__item_icon']}>
              <FileIcon width={14} height={14} viewBox="0 0 14 14" />
            </div>
            <div className={classes['documents-list__item_info']}>
              <div className={classes['documents-list__item_header']}>
                <span
                  className={classes['documents-list__item_label']}
                  onClick={() => handleDownload(doc.file, true)}
                >
                  {doc?.file_name || doc.file.split('/').at(-1)}
                </span>
                <span className={classes['documents-list__item_size']}>
                  {(doc.size / 1024 / 1024).toFixed(3) + t('Mb')}
                </span>
              </div>
              <span className={classes['documents-list__item_desc']}>
                {doc.description || t('file')}
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default ProjectDocuments;
