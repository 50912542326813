import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import classes from './ProjectGoals.module.scss';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../../stores/user-store';
import { partnerStore } from 'stores/partnerStore';
import _ from 'lodash';
import { pushToDataLayer } from '../../../tools/analytics';
import UiIcon from '../../../components/shared/Icon';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { EndIconProps } from 'containers/AddSolution/Input';
import { getLangName } from '../../../tools/utils';
import Partner from 'services/partner';

interface Props {
  project?: any;
  userGoals?: any;
  updateProject?: any;
  tabsId?: string;
  disabled?: boolean;
  title?: string;
  isAddSolution?: boolean;
  systemGoals?: any;
  setSolutionGoals?: any;
  error?: boolean;
  onChange?: any;
  endIcon?: EndIconProps;
}

interface SystemGoal {
  name: string;
  id?: number;
}

export const ProjectGoalsContainer: FC<Props> = observer(
  ({
    onChange,
    project,
    isAddSolution,
    systemGoals,
    userGoals,
    setSolutionGoals,
    error,
    updateProject,
    tabsId,
    disabled,
    title,
    endIcon,
  }) => {
    const { t, i18n } = useTranslation();
    const internalGoals = isAddSolution ? [] : project?.goals;
    const internalSystemGoals = isAddSolution
      ? systemGoals
      : project?.project_system_goals;

    const editableDivRef = useRef<HTMLInputElement>(null);
    const [projectGoals, setProjectGoals] =
      useState<SystemGoal[]>(internalGoals);
    const [systemProjectGoals, setSystemProjectGoal] =
      useState<SystemGoal[]>(internalSystemGoals);
    const [addAnotherGoal, setAddAnotherGoal] = useState<boolean>(false);
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [newGoal, setNewGoal] = useState<SystemGoal | null>(null);
    const [projectId, setProjectId] = useState<number>();
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const userLocale = userStore.user?.language?.lang_code;
    const maxLength = 40;

    const {
      endIconName,
      endIconTooltipText,
      endIconTooltipPosition,
      endIconTooltipClassName,
      endIconClassName,
    } = endIcon || {};

    useEffect(() => {
      if (newGoal) {
        setProjectId(project?.id || 1);
      }
      if (!newGoal) {
        setIsFocused(false);
      }
    }, [newGoal, project?.id]);

    // Функция для сохранения очередности при смене локали и добавлении новых целей
    /*const updateGoals = (updatedGoals: SystemGoal[]) => {
    const updatedGoalsMap = new Map(updatedGoals.map(goal => [goal.id, goal]));

    const newProjectGoals = projectGoals?.map(goal => {
      if (updatedGoalsMap.has(goal?.id)) {
        return updatedGoalsMap.get(goal?.id);
      }
      return goal;
    });

    updatedGoals.forEach(updatedGoal => {
      if (!projectGoals.some(goal => goal.id === updatedGoal.id)) {
        newProjectGoals.push(updatedGoal);
      }
    });

    setProjectGoals(newProjectGoals as SystemGoal[])
  };*/

    useEffect(() => {
      if (disabled) return;
      if (isAddSolution) {
        if (userGoals?.length) setProjectGoals(userGoals);
        return;
      } else {
        updateProject().then((project: any) => {
          setSystemProjectGoal(project?.data?.project_system_goals);
          setProjectGoals(project?.data?.goals);
        });
      }
    }, [disabled, userLocale, isAddSolution]);

    const checkGoals = (goalsArray: SystemGoal[], goal: SystemGoal) =>
      _.findIndex(goalsArray, (o) => _.isMatch(o, goal)) > -1;

    const handleGoal = async (
      projectId: number,
      goal: any,
      removed = false
    ) => {
      setIsClicked(true);
      const finalGoal = goal?.goal ? goal.goal : goal;

      if (isAddSolution) {
        if (!removed) {
          setProjectGoals((prevState) =>
            prevState.find((goal) => goal?.name === finalGoal?.name)
              ? prevState
              : [...prevState, finalGoal]
          );
          setSolutionGoals && setSolutionGoals([...projectGoals, finalGoal]);
          onChange([...projectGoals, finalGoal]);
          setSystemProjectGoal((prevState) =>
            prevState?.filter((systemGoal) => systemGoal?.id !== finalGoal?.id)
          );
        } else if (removed) {
          setProjectGoals((prevState) => {
            onChange(
              prevState?.filter(
                (projectGoal) => projectGoal?.name !== finalGoal?.name
              )
            );
            return prevState?.filter(
              (projectGoal) => projectGoal?.name !== finalGoal?.name
            );
          });
          setSystemProjectGoal((prevState) =>
            finalGoal?.id ? [...prevState, finalGoal] : prevState
          );
          setSolutionGoals &&
            setSolutionGoals((prevState: any) => {
              const filterGoals = prevState?.filter(
                (solutionGoal: any) => solutionGoal?.name !== finalGoal?.name
              );
              onChange(filterGoals);
              return filterGoals;
            });
        }
        setTimeout(() => setIsClicked(false), 500);
        return;
      }

      try {
        if (removed) {
          await Partner.deleteGoal(projectId, finalGoal?.id);
          const data = await updateProject();
          setSystemProjectGoal(data.project_system_goals);
          setProjectGoals(data.goals);
        } else if (!checkGoals(projectGoals, finalGoal)) {
          const data = await Partner.updateProject(projectId, finalGoal?.name);
          setProjectGoals(data?.goals);
          setSystemProjectGoal(data?.project_system_goals);
        }
      } catch (error) {
        console.error(error);
      } finally {
        updateProject();
      }
      setTimeout(() => setIsClicked(false), 500);
    };

    const handleAddAnotherGoal = () => {
      handleGoal(project?.id, newGoal);
      setNewGoal(null);
      setAddAnotherGoal(false);
      setIsFocused(false);
      setProjectId(undefined);
    };

    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        handleAddAnotherGoal();
      }
    };

    const icon = () => (
      <svg
        onClick={() => {
          setNewGoal(null);
          setAddAnotherGoal(false);
          setIsFocused(false);
          setProjectId(undefined);
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8.00016 3.33301V12.6663M3.3335 7.99967H12.6668"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
    const handleNonInputClick = (event: any) => {
      if (event.target.closest(`#${tabsId}`)) return;
      if (
        !event.target.closest('input') &&
        !event.target.closest('svg') &&
        projectId &&
        isFocused
      ) {
        event.preventDefault();
        handleAddAnotherGoal();
      } else if (
        !event.target.closest('input') &&
        !event.target.closest('svg') &&
        !isFocused &&
        !newGoal?.name
      ) {
        event.preventDefault();
        setNewGoal(null);
        setAddAnotherGoal(false);
        setProjectId(undefined);
      }
    };

    useEffect(() => {
      if (disabled) return;
      document.addEventListener('click', handleNonInputClick);
      return () => {
        document.removeEventListener('click', handleNonInputClick);
      };
    }, [newGoal, projectId, isFocused, handleNonInputClick]);

    /*
  const updateProjectGoals = async (goal: SystemGoal, removed: boolean) => {
    if (!removed && !checkGoals(projectGoals, goal)){
        setProjectGoals((projectGoals: SystemGoal[]) => [...projectGoals, goal]);
      } else {
        const updatedProjectGoals = projectGoals.filter((projectGoal: SystemGoal) => projectGoal?.id !== goal?.id)
        setProjectGoals(updatedProjectGoals)

        if (checkGoals(AllSystemGoals, goal)){
          setSystemProjectGoal([...systemProjectGoals, goal])
        }
        return
      }

      if (checkGoals(systemProjectGoals, goal)){
        const updatedSystemGoals = systemProjectGoals.filter((systemGoal: SystemGoal) => systemGoal?.id !== goal?.id);
        setSystemProjectGoal(updatedSystemGoals);
      }
  };*/

    const getGoalChoice = (goals: SystemGoal[]) =>
      goals.map((goal: SystemGoal) => (
        <div
          className={classes.goals__item}
          onClick={() => {
            if (!isClicked) {
              handleGoal(project?.id, goal);
              pushToDataLayer('addGoals', 'buttonClick', 'GAForms');
            }
          }}
        >
          <div>{getLangName(goal, 'name', i18n)}</div>
          {icon()}
        </div>
      ));

    const getProjectGoals = (goals: any) =>
      goals?.map((goal: any) => {
        const finalGoal = goal?.goal ? goal.goal : goal;
        return (
          <div
            className={clsx(classes.goals__item, classes.goals__item_active)}
            onClick={() => {
              handleGoal(project?.id, goal, true);
              setIsClicked(true);
            }}
          >
            <div>{getLangName(finalGoal, 'name', i18n)}</div>
            <span className={classes.goalLength}>
              {goal?.name?.length}/{maxLength}
            </span>
            {icon()}
          </div>
        );
      });

    useEffect(() => {
      if (addAnotherGoal && editableDivRef.current) {
        editableDivRef.current.focus();
      }
    }, [addAnotherGoal]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value.length <= maxLength) {
        setNewGoal({ name: value });
        e.target.style.width = `${e.target.scrollWidth}px`;
      }
    };

    return (
      <div className={classes.goals}>
        {title && (
          <div className={classes.goals__title}>
            <span>{title} </span>
            {endIconName && (
              <TooltipContainer
                text={endIconTooltipText}
                customClasses={'kit-ui-block'}
                position={endIconTooltipPosition}
                className={clsx(
                  endIconTooltipClassName,
                  classes['end-icon__tooltip']
                )}
              >
                <UiIcon
                  name={endIconName}
                  additionalClassName={clsx(
                    classes.infoIcon,
                    endIconClassName,
                    error && classes.errorIcon
                  )}
                />
              </TooltipContainer>
            )}
          </div>
        )}
        <div className={clsx(classes.goals__main, error && classes.error)}>
          {getProjectGoals(projectGoals)}

          {addAnotherGoal && (
            <div
              className={clsx(classes.goals__item, classes.goals__item_active)}
              onKeyDown={handleKeyDown}
            >
              <input
                ref={editableDivRef}
                className={classes.input}
                value={newGoal?.name}
                maxLength={40}
                onChange={handleChange}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  !newGoal?.name && setIsFocused(false);
                }}
              />
              <span className={classes.goalLength}>
                {newGoal?.name?.length || 0}/{maxLength}
              </span>
              {icon()}
            </div>
          )}
          {projectGoals?.length > 0 && (
            <div
              className={clsx(classes.goals__item, classes.goals__item_add)}
              onClick={() => setAddAnotherGoal(true)}
            >
              <span>{t('Add another…')}</span>
              {icon()}
            </div>
          )}
          {projectGoals?.length <= 0 && !addAnotherGoal && (
            <div className={clsx(classes.goals__empty_block)}>
              <p className={clsx(classes.goals__empty_text)}>
                {t('Add goals so they appear here')}
                <br />
                {error && t('Fill in the field')}
              </p>
              <div
                className={clsx(classes.goals__empty_btn)}
                onClick={() => {
                  setAddAnotherGoal(true);
                }}
              >
                {t('Add goal')}
                {icon()}
              </div>
            </div>
          )}
        </div>

        {systemProjectGoals?.length > 0 && (
          <div className={classes.goals__bottom_text}>
            {t('Perhaps your goals are among these')}
          </div>
        )}
        <div className={classes.goals__bottom}>
          {getGoalChoice(systemProjectGoals)}
        </div>
      </div>
    );
  }
);
