import React, { FC, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { StepInput } from './StepInput';
import classes from './StepsContainer.module.scss';
import UiIcon from 'components/shared/Icon';
import { useTranslation } from 'react-i18next';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import clsx from 'clsx';
import {
  FormValidationProps,
  Step,
} from 'containers/AddSolution/FormBlock/FormBlock.props';
import { stepTitles } from 'containers/AddSolution/utils';

export interface EndIconProps {
  endIconName?: string;
  endIconTooltipText?: string;
  endIconTooltipPosition?: string;
  endIconTooltipClassName?: string;
  endIconClassName?: string;
}

interface StepsContainerProps {
  title?: string;
  error?: string;
  disableDelete?: boolean;
  infoIconOptions?: EndIconProps;
  endIconOptions?: EndIconProps;
  initialSteps: Step[];
  defaultValues: Step[];
}

export const StepsContainer: FC<StepsContainerProps> = ({
  title,
  error,
  initialSteps,
  defaultValues,
  endIconOptions,
  disableDelete,
  infoIconOptions,
}) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValidationProps>();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'steps',
  });

  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  useEffect(() => {

    const steps = fields.map((field: any, index: number) => {
      let stepTitle
      if (index < stepTitles(t).length) {
        stepTitle = `${stepTitles(t)[index]} ${t('step')}`;
      } else {
        stepTitle = t('Step {{step}}', { step: index + 1 });
      }
      return {
        step: index + 1,
        title: stepTitle,
        value: field.value,
      }
    })
    replace(steps)
  }, [isDeleted]);

  useEffect(() => {
    if (defaultValues?.length !== 0) return;
    replace(initialSteps);
  }, [initialSteps, replace]);

  const addStep = (e: any) => {
    e.preventDefault();
    const nextStepIndex = fields.reduce(
      (max, field: any) => Math.max(max, Number(field?.step)),
      0
    );
    let stepTitle;
    if (nextStepIndex < stepTitles(t).length) {
      stepTitle = `${stepTitles(t)[nextStepIndex]} ${t('step')}`;
    } else {
      stepTitle = t('Step {{step}}', { step: nextStepIndex + 1 });
    }

    append({
      step: nextStepIndex + 1,
      title: stepTitle,
      value: '',
    });
  };

  const deleteStep = (index: any) => {
    remove(index);
    setIsDeleted(!isDeleted)
  };

  const {
    endIconName: infoIconName,
    endIconTooltipText: infoIconTooltipText,
    endIconTooltipPosition: infoIconTooltipPosition,
    endIconTooltipClassName: infoIconTooltipClassName,
    endIconClassName: infoIconClassName,
  } = infoIconOptions || {};

  return (
    <div className={classes.stepsContainer}>
      {title && (
        <div className={classes.stepsContainer__title}>
          <span>{title}</span>
          {infoIconName && (
            <TooltipContainer
              text={infoIconTooltipText}
              customClasses={'kit-ui-block'}
              position={infoIconTooltipPosition}
              className={clsx(
                infoIconTooltipClassName,
                classes['end-icon__tooltip']
              )}
            >
              <UiIcon
                name={infoIconName}
                additionalClassName={clsx(
                  classes.infoIcon,
                  infoIconClassName,
                  error && classes.errorIcon
                )}
              />
            </TooltipContainer>
          )}
        </div>
      )}
      {fields?.map((field, index) => (
        <Controller
          key={field.id}
          name={`steps.${index}.value`}
          control={control}
          render={({ field: controllerField, fieldState }) => {
            const errorMessage =
              fieldState.error?.type === 'required' && index < 1
                ? error
                : index &&
                  (errors?.steps?.[index]?.step?.message ||
                    errors?.steps?.[index]?.value?.message);
            return (
              <StepInput
                endIcon={endIconOptions}
                error={errorMessage as string}
                maxLength={50}
                step={fields[index] as any}
                value={controllerField.value as string}
                canDelete={index > 0 && !disableDelete}
                onChange={(event: any) => {
                  controllerField.onChange(event);
                }}
                onDelete={() => deleteStep(index)}
              />
            );
          }}
        />
      ))}
      <div className={classes.addButton} onClick={addStep}>
        <UiIcon name="PlusIcon" additionalClassName={classes.plusIcon} />
        <span>{t('Add step')}</span>
      </div>
    </div>
  );
};
