import { MultiSelectOption } from 'components/shared/MultiSelect';
import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { isOnboardingActive, setOnboardingOptions } from 'tools/onboarding';
import { onDelete, onSelect } from '../../utils';
import classes from '../FormBlock.module.scss';
import { FormValidationProps } from '../FormBlock.props';
import { MultiSelectProps } from './FirsStep.props';

export const useFirstStep = (
  companySizes: MultiSelectOption[],
  industryTypes: MultiSelectOption[],
  setCurrentStep?: (step: number) => void,
  disabled?: boolean
) => {
  const { isMediaTablet, isSmallLaptop: isLaptop } = useWindowWidth();
  const userStore = useUserStore();

  const name = useWatch({ name: 'name' });
  const productType = useWatch({ name: 'productType' });
  const industry = useWatch({ name: 'industry' });
  const goals = useWatch({ name: 'goals' });
  const shortDescriptionValue = useWatch({ name: 'shortDescription' });
  const fullDescriptionValue = useWatch({ name: 'fullDescription' });
  const projectDurationValue = useWatch({ name: 'projectDuration' });
  const steps = useWatch({ name: 'steps' });
  const defaultCompanySizes = useWatch({ name: 'companySize' });

  const [selectIndustryTypes, setSelectIndustryTypes] = useState<
    MultiSelectProps[]
  >(industry || []);
  const [selectedCompanySizes, setSelectedCompanySizes] = useState(
    defaultCompanySizes || []
  );
  const [internalCompanySizes, setInternalCompanySizes] = useState<
    MultiSelectOption[]
  >([]);
  const [internalIndustries, setInternalIndustries] = useState<
    MultiSelectOption[]
  >([]);
  const [generalCount, setGeneralCount] = useState<number>(0);
  const [detailCount, setDetailCount] = useState<number>(0);
  const [materialCount, setMaterialCount] = useState<number>(0);
  const enableHints =
    isOnboardingActive(userStore?.pagesToOnboard?.solutionMain) && !disabled;

  const handleExit = useCallback(() => {
    if (!disabled) {
      setOnboardingOptions({ solutionMain: true });
      userStore.enableOnboarding(true);
    }
  }, [userStore.enableOnboarding]);

  const file = useWatch({ name: 'useCases-2' });

  const {
    trigger,
    formState: { errors },
    control,
  } = useFormContext<FormValidationProps>();

  useEffect(() => {
    setGeneralCount(
      [name, productType, industry, defaultCompanySizes, goals?.length].filter(
        (item) => item
      ).length
    );
  }, [name, productType, industry, defaultCompanySizes, goals]);

  useEffect(() => {
    setDetailCount(
      [
        shortDescriptionValue,
        fullDescriptionValue,
        projectDurationValue,
        steps[0]?.value,
      ].filter((item) => item).length
    );
  }, [
    shortDescriptionValue,
    fullDescriptionValue,
    projectDurationValue,
    steps,
  ]);

  useEffect(() => {
    setMaterialCount([file.name].filter((item) => item).length);
  }, []);

  useEffect(() => {
    companySizes && setInternalCompanySizes(companySizes);
  }, [companySizes]);

  useEffect(() => {
    industryTypes && setInternalIndustries(industryTypes);
  }, [industryTypes]);

  const endIconOptions = (title: string) => ({
    endIconName: 'HelpIcon',
    endIconTooltipText: title,
    endIconTooltipPosition: isMediaTablet ? 'top' : 'right',
    endIconTooltipClassName: classes.endIconTooltip,
    endIconClassName: classes.endIcon,
  });

  const onValidateClick = async () => {
    const scrollToTop = window?.scrollTo({ top: 0, behavior: 'smooth' });
    if (trigger) {
      const isValid = await trigger();
      if (isValid && setCurrentStep) {
        setCurrentStep(2);
        return scrollToTop;
      } else {
        return scrollToTop;
      }
    }
  };

  const callbacks = {
    onSelectIndustry: (id: number | string, onChange: any) =>
      onSelect(id, industryTypes, setSelectIndustryTypes, onChange),
    onDeleteSelectedIndustry: (id: number | string, onChange: any) =>
      onDelete(id, setSelectIndustryTypes, onChange),
    onSelectCompanySizes: (id: number | string, onChange: any) =>
      onSelect(id, companySizes, setSelectedCompanySizes, onChange),
    onDeleteCompanySize: (id: number | string, onChange: any) =>
      onDelete(id, setSelectedCompanySizes, onChange),
  };

  return {
    isLaptop,
    enableHints,
    handleExit,
    generalCount,
    endIconOptions,
    errors,
    control,
    internalIndustries,
    isMediaTablet,
    selectIndustryTypes,
    callbacks,
    setInternalIndustries,
    selectedCompanySizes,
    internalCompanySizes,
    setInternalCompanySizes,
    detailCount,
    materialCount,
    onValidateClick,
    file,
    setMaterialCount,
  };
};
