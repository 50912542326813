import { IStatus } from '@type/api';
import { GetSolutionProjectsResponse, SolutionProjects } from '@type/projects';
import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import ProjectsService from 'services/projects';
import StatusFactory from 'tools/status';

export class ProjectsStore {
  solutionsWithProjects: SolutionProjects[] = [];
  loadSolutionsProjectsStatus: IStatus<GetSolutionProjectsResponse | null> =
    StatusFactory.default();
  allProjectsCount: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  public resetSolutionsProjectsStatus(): void {
    this.loadSolutionsProjectsStatus = StatusFactory.default();
  }

  public async loadSolutionsWithProjects(
    params?: string,
    onError?: () => void
  ) {
    this.loadSolutionsProjectsStatus = StatusFactory.loading();

    try {
      const response = await ProjectsService.getSolutionProjects(params);

      runInAction(() => {
        this.solutionsWithProjects = response.data.results;
        this.allProjectsCount = response.data.allProjectsCount;
        this.loadSolutionsProjectsStatus = StatusFactory.success(response.data);
      });
    } catch (error) {
      onError?.();
      runInAction(() => {
        this.loadSolutionsProjectsStatus = StatusFactory.failed(
          error as AxiosError
        );
      });
    }
  }
}

export const projectsStore = new ProjectsStore();
