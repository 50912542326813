import { useEffect, useRef, useState } from 'react';
import classes from './StepInput.module.scss';
import UiIcon from 'components/shared/Icon';
import clsx from 'clsx';
import style from 'components/shared/Input/Input.module.scss';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { EndIconProps } from '..';
import { Step } from 'containers/AddSolution/FormBlock/FormBlock.props';

interface StepInputProps {
  step?: Step;
  canDelete?: boolean;
  onDelete?: (e: any) => void;
  icon?: boolean;
  error?: string;
  onChange: (e: any) => void;
  value?: string;
  endIcon?: EndIconProps;
  maxLength?: number;
}

export const StepInput = ({
  onChange,
  step,
  maxLength,
  value,
  canDelete,
  onDelete,
  error,
  endIcon,
}: StepInputProps) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    endIconName,
    endIconTooltipText,
    endIconTooltipPosition,
    endIconTooltipClassName,
    endIconClassName,
  } = endIcon || {};

  useEffect(() => {
    if (inputRef.current && focused) {
      inputRef.current.focus();
    }
  }, [focused, inputRef]);

  const classNames = clsx(
    style.input,
    error && style.inputError,
    classes.input
  );

  return (
    <div className={clsx(classes.step, error && classes.error)}>
      <div
        className={clsx(classes.inputWrap, focused && classes.inputFocused)}
        onClick={() => setFocused(true)}
      >
        <div className={classes.stepNumber}>{step?.step as number}</div>
        <div className={classes.inputWithTitle}>
          {focused && <span className={classes.stepTitle}>{step?.title}</span>}
          {focused ? (
            <input
              ref={inputRef}
              className={classNames}
              value={value}
              maxLength={maxLength}
              onChange={(e) => onChange(e.target.value)}
              onBlur={() => setFocused(false)}
            />
          ) : (
            <div className={classes.inputValue}>
              <span
                className={clsx(classes.stepTitle, value && classes.active)}
              >
                {step?.title}
              </span>
              <span>{value}</span>
            </div>
          )}
        </div>
        {!canDelete && endIconName && (
          <TooltipContainer
            text={endIconTooltipText}
            customClasses={'kit-ui-block'}
            position={endIconTooltipPosition}
            className={endIconTooltipClassName}
          >
            <UiIcon
              name={endIconName}
              additionalClassName={clsx(
                classes.endIcon,
                endIconClassName,
                error && classes.error
              )}
            />
          </TooltipContainer>
        )}
        {canDelete && (
          <button className={classes.deleteButton} onClick={onDelete}>
            <UiIcon name="RemoveIcon" />
          </button>
        )}
      </div>
      {error?.length && <div className={classes.errorText}>{error}</div>}
    </div>
  );
};
