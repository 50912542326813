import React, { FC } from "react";
import classes from "./Toast.module.scss";
import { ToastContainer } from "react-toastify";
import clsx from "clsx";
import { CheckCircleIcon } from "components/shared/Icons/CheckCirleIcon";
import { UnionIcon } from "components/shared/Icons/UnionIcon";
import { InfoIcon } from "components/shared/Icons/InfoIcon";

interface Props {
  type: string;
  text?: string;
  toastClass?: any;
  toastIconClass?: any;
  childrenClass?: any;
}

export const Toast: FC<Props> = ({
  type,
  text,
  toastClass,
  childrenClass,
  toastIconClass,
}) => {
  return (
    <div
      className={clsx(classes["toast-container"], classes[type], toastClass)}
    >
      <div className={clsx(classes.toast, classes[type], toastClass)}>
        <div className={clsx(classes.icon, toastIconClass)}>
          {type === "success" && (
            <CheckCircleIcon fill={"#00C853"} width={"100%"} height={"100%"} />
          )}
          {type === "warning" && (
            <InfoIcon fill={"#F3CA37"} width={"100%"} height={"100%"} />
          )}
          {type === "error" && (
            <UnionIcon fill={"#F5222D"} width={"100%"} height={"100%"} />
          )}
        </div>
        <span className={clsx(classes.text, childrenClass)}>{text}</span>
      </div>
    </div>
  );
};
