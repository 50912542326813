import clsx from 'classnames';
import { FILTERS } from 'constants/enum';
import { useTranslation } from 'react-i18next';
import { FilterTypes } from 'types/partner';
import classes from './Filters.module.scss';
import { FiltersProps } from './Filters.props';

export const Filters = ({
  all,
  active,
  inReview,
  archived,
  filter,
  setFilter,
}: FiltersProps) => {
  const { t } = useTranslation();

  const filterOptions = [
    { label: 'All', count: all, filterType: FILTERS.ALL },
    { label: 'Active', count: active, filterType: FILTERS.ACTIVE },
    { label: 'In review', count: inReview, filterType: FILTERS.IN_REVIEW },
    { label: 'Archived', count: archived, filterType: FILTERS.ARCHIVED },
  ];

  return (
    <div className={classes.wrapper}>
      {filterOptions.map(({ label, count, filterType }) => (
        <div
          key={`${label}-${filterType}`}
          role="button"
          tabIndex={-1}
          onClick={() => {
            if (count !== 0) {
              setFilter(filterType as FilterTypes);
            }
          }}
          className={clsx(
            classes.filter,
            filter === filterType && classes.active,
            count === 0 && classes.disabled
          )}
        >
          {t(label)}
          <div
            className={clsx(classes.count, count > 9 && classes.countMedium)}
          >
            {count}
          </div>
        </div>
      ))}
    </div>
  );
};
