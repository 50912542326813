import * as yup from 'yup';

const stepSchema = (t: any) =>
  yup.object().shape({
    step: yup.number().required(t('Fill in the field')),
    title: yup.string().required(t('Fill in the field')),
    value: yup
      .string()
      .max(64, t('Value must be at most 64 characters\n'))
      .required(t('Fill in the field')),
  });

const goalSchema = (t: any) =>
  yup.object().shape({
    name: yup.string().required(t('Fill in the field')),
    id: yup.number(),
  });

const fileValidation = (t: any) =>
  yup
    .mixed()
    .test(
      'fileSize',
      t('File size exceeds 10 MB'),
      (value: any) => value?.size <= 10 * 1024 * 1024
    )
    .test(
      'fileName',
      t('Invalid file name'),
      (value: any) => value?.name?.length > 0
    );

const platformSchema = (t: any) =>
  yup.object().shape({
    id: yup.number().required(t('Fill in the field')),
  });

const kpiItemSchema = (t: any) =>
  yup.object().shape({
    title: yup.string(),
    name: yup.string().required(t('Fill in the field')),
    value: yup.string().required(t('Fill in the field')),
    valueType: yup.string().required(t('Fill in the field')),
    unit: yup.string().required(t('Fill in the field')),
  });

const caseSchema = (t: any) =>
  yup.object().shape({
    caseName: yup.string().required(t('Fill in the field')),
    caseBusinessType: yup.string().required(t('Fill in the field')),
    keyAchievements: yup.string().required(t('Fill in the field')),
    secondStepDescription: yup.string().required(t('Fill in the field')),
    kpi: yup.array().of(kpiItemSchema(t)).min(1, t('Please, add KPI data')),
    platforms: yup
      .array()
      .of(platformSchema(t))
      .min(1, t('Fill in the field'))
      .required(t('Fill in the field')),
  });

export const firstStepSchema = (t: any) =>
  yup.object().shape({
    name: yup.string().required(t('Fill in the field')),
    productType: yup
      .number()
      .required(t('Fill in the field'))
      .notOneOf([0], t('Fill in the field')),
    companySize: yup
      .array()
      .min(1, t('Fill in the field'))
      .required(t('Fill in the field')),
    industry: yup.array().required(t('Fill in the field')),
    shortDescription: yup.string().required(t('Fill in the field')),
    fullDescription: yup.string().required(t('Fill in the field')),
    projectDuration: yup.string().required(t('Fill in the field')),
    ['useCases-2']: fileValidation(t).required(t('Select file')),
    goals: yup
      .array()
      .of(goalSchema(t))
      .min(1, t('Fill in the field'))
      .required(t('Fill in the field')),
    steps: yup.array().of(stepSchema(t)).required(t('Fill in the field')),
  });

export const secondStepSchema = (t: any) =>
  yup.object().shape({
    cases: yup.array().of(caseSchema(t)),
  });

export const thirdStepSchema = (t: any) =>
  yup.object().shape({
    price: yup.number().when('is_free', {
      is: false,
      then: (schema) =>
        schema.min(1, t('Fill in the field')).required(t('Fill in the field')),
      otherwise: (schema) => schema.notRequired(),
    }),
    currency: yup.string().when('is_free', {
      is: false,
      then: (schema) => schema.required(t('Fill in the field')),
      otherwise: (schema) => schema.notRequired(),
    }),
    is_free: yup.boolean(),
    countries: yup
      .array()
      .min(1, t('Fill in the field'))
      .required(t('Fill in the field')),
    payment_type: yup
      .array()
      .min(1, t('Fill in the field'))
      .required(t('Fill in the field')),
  });
