import clsx from 'classnames';
import UiIcon from 'components/shared/Icon';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { usePartnerStore } from 'contexts/PartnerContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './AddSolution.module.scss';
import { FormBlock } from './FormBlock';

export const AddSolution = ({ title }: { title: string }) => {
  const { copiedService, clearCopyOfService } = usePartnerStore() || {};
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { t } = useTranslation();
  const { isMediaTablet } = useWindowWidth();

  useEffect(() => {
    clearCopyOfService();
  }, []);

  useEffect(() => {
    const layoutContainer = document.querySelector(
      '.layout-container'
    ) as HTMLDivElement;

    if (isMediaTablet && layoutContainer) {
      layoutContainer.style.paddingLeft = '0';
      layoutContainer.style.paddingRight = '0';
    } else {
      layoutContainer.style.paddingLeft = '20px';
      layoutContainer.style.paddingRight = '20px';
    }

    return () => {
      if (layoutContainer) {
        layoutContainer.style.paddingLeft = '20px';
        layoutContainer.style.paddingRight = '20px';
      }
    };
  }, [isMediaTablet]);

  return (
    <div className={classes.addSolutionContainer}>
      <div className={classes.title}>{title}</div>
      <div className={classes.formBlockContainer}>
        <div className={classes.stepsBlock}>
          <TitleAndDescription pageName="AddSolution" />

          <div
            className={clsx(
              classes.step,
              currentStep === 1 && classes.active,
              currentStep > 1 && classes.done
            )}
          >
            {currentStep > 1 ? (
              <UiIcon
                name="CheckIcon"
                additionalClassName={classes.stepNumber}
              />
            ) : (
              <div className={classes.stepNumber}>1</div>
            )}
            <div className={classes.stepTitle}>{t('Main information')}</div>
          </div>

          <span className={classes.stepLine} />

          <div
            className={clsx(
              classes.step,
              currentStep === 2 && classes.active,
              currentStep > 2 && classes.done
            )}
          >
            {currentStep > 2 ? (
              <UiIcon
                name="CheckIcon"
                additionalClassName={classes.stepNumber}
              />
            ) : (
              <div className={classes.stepNumber}>2</div>
            )}
            <div className={classes.stepTitle}>{t('Cases')}</div>
          </div>

          <span className={classes.stepLine} />

          <div
            className={clsx(
              classes.step,
              currentStep === 3 && classes.active,
              currentStep > 3 && classes.done
            )}
          >
            <div className={classes.stepNumber}>3</div>
            <div className={classes.stepTitle}>{t('Price')}</div>
          </div>
        </div>

        <FormBlock
          title={t('General overview')}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          copiedService={copiedService}
        />
      </div>
    </div>
  );
};
