import { TFunction } from 'i18next';
import { MultiSelectProps } from './FormBlock/FirstStep/FirsStep.props';

export const onSelect = (
  id: number | string,
  options: any,
  setSelect: React.Dispatch<React.SetStateAction<MultiSelectProps[]>>,
  onChange: any
) => {
  const selectedElement = options?.find((product: any) => product.value === id);

  if (id === 'all') {
    setSelect(
      options.map((item: any) => ({
        id: item.value || item.id,
        name: item.label || item.name,
      }))
    );

    onChange(
      options.map((item: any) => ({
        id: item.value || item.id,
        name: item.label || item.name,
      }))
    );
    return;
  }

  if (selectedElement) {
    setSelect((prevSelected: any) => {
      onChange(
        [...prevSelected, selectedElement].map((item: any) => ({
          id: item.value || item.id,
          name: item.label || item.name,
        }))
      );

      return [
        ...prevSelected,
        {
          id: selectedElement.value,
          name: selectedElement.label,
        },
      ];
    });
  }
};

export const onDelete = (
  id: number | string,
  setSelect: React.Dispatch<React.SetStateAction<MultiSelectProps[]>>,
  onChange?: any
) => {
  if (id === 'all') {
    setSelect([]);
    return;
  }

  setSelect((prevSelected: any) => {
    onChange(
      prevSelected.filter(
        (product: any) => (product?.id || product?.type) !== id
      )
    );

    return prevSelected.filter(
      (product: any) => (product?.id || product?.type) !== id
    );
  });
};

export const convertFileToBase64 = (file: any) => {
  if (file) {
    if (file?.id) return false;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        resolve(event.target.result.split(',')[1]);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }
};

export const initialSteps = (t: any) => [
  {
    step: 1,
    title: t('First step'),
    value: '',
  },
  // {
  // 	step: 2,
  // 	title: t("Second step"),
  // 	value:
  // 		"",
  // },
  // {
  // 	step: 3,
  // 	title: t("Third step"),
  // 	value:
  // 		"",
  // },
  // {
  // 	step: 4,
  // 	title: t("Fourth step"),
  // 	value:
  // 		"",
  // },
  // {
  // 	step: 5,
  // 	title: t("Fifth step"),
  // 	value:
  // 		"",
  // },
];

export const materialsData = (t: TFunction) => [
  {
    name: t('Add brief'),
    description: t('Maximum file size 10 Mb (pdf, doc, docx)'),
    controlName: 'useCases-2',
  },
];

export const stepTitles = (t: TFunction) => [
  t('First'),
  t('Second'),
  t('Third'),
  t('Fourth'),
  t('Fifth'),
  t('Sixth'),
  t('Seventh'),
  t('Eighth'),
  t('Ninth'),
  t('Tenth'),
];

export const multiFieldValues = (
  selected: any,
  field: any,
  hideOrder?: boolean
) => {
  const filledArray =
    field?.value || (selected.length ? selected : field?.value) || [];

  return filledArray?.map(
    (
      { id, name, type }: { id: number | string; name: string; type: string },
      index: number
    ) => ({
      value: id || type,
      label: name,
      ...(!hideOrder && { order: index + 1 }),
    })
  );
};
