import { ProjectStatusEnum } from 'containers/Projects/types';
import { NameType } from '@type/util-types';
import { Currency } from '@type/finance';

export type TProjectManager = {
  first_name?: string;
  image?: string;
  is_active: boolean;
  last_name?: string;
  name: string;
  office?: number;
};

export enum DealStageTypeEnum {
  ANALYSIS = 'ANALYSIS',
  BRIEFING = 'BRIEFING',
  CREATION = 'PROPOSAL_CREATION',
  PRESENTED = 'PROPOSAL_PRESENTED',
  NEGOTIATIONS = 'NEGOTIATIONS',
  SIGNING = 'SIGNING_AGREEMENT',
  SIGNED = 'AGREEMENT_SIGNED',
  ASSIGNMENT = 'ACCOUNT_ASSIGNMENT',
  ONBOARDING = 'ONBOARDING',
  PREPARATION_LAUNCH = 'PREPARATION_FOR_LAUNCH',
  LAUNCHED = 'LAUNCHED',
  PRODUCTION = 'PRODUCTION',
  PAUSED = 'PAUSED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
}

export type TDealStage = {
  id: number;
  type: DealStageTypeEnum;
  plan_end_date: string;
  fact_end_date?: string;
  name: string;
  description: string;
};

type TDescription = {
  description: string;
  description_en: string;
  description_hi: string;
  description_es: string;
  description_es_mx: string;
  description_ind: string;
  description_ru: string;
};

export type TProjectFile = {
  deal_stage: number;
  size: number;
  file: string;
  file_name: string;
} & TDescription;

export type TProjectGoal = {
  id: number;
} & NameType;

export type TStageType = {
  name: string;
  type: string;
  description: string;
};

export type TProject = {
  id: number;
  source?: {
    id: number;
    name: string;
  };
  solution: {
    id: number;
    name: string;
    slug: string;
    is_free: boolean;
  };
  manager?: TProjectManager;
  all_stages: TStageType[];
  deal_stage: TDealStage;
  deal_stages: TDealStage[];
  cost: {
    type: string;
    cost: number;
  }[];
  number: string;
  files: TProjectFile[];
  legal_entity?: {
    id: number;
    name?: string;
    address?: string;
  };
  status: ProjectStatusEnum;
  project_system_goals: TProjectGoal[];
  all_system_goals: TProjectGoal[];
  goals: TProjectGoal[];
  currency: Currency;
  zoho_opportunity_id?: number;
  is_created_in_zoho: boolean;
  office?: number;
} & NameType &
  TDescription;

export type FilterTypes = 'all' | 'is_active' | 'is_archived' | 'is_review';
