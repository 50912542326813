import { AuthProvider } from 'contexts/AuthContext';
import { MarketPlaceProvider } from 'contexts/marketPlaceContext';
import { UserProvider } from 'contexts/UserContext';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import { CommonProvider } from 'contexts/CommonContext';
import { PartnerProvider } from './contexts/PartnerContext';
import { FinancesProvider } from 'contexts/FinancesContext';
import { ProjectsProvider } from 'contexts/ProjectsContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <CommonProvider>
      <UserProvider>
        <MarketPlaceProvider>
          <PartnerProvider>
            <FinancesProvider>
              <ProjectsProvider>
                <App />
              </ProjectsProvider>
            </FinancesProvider>
          </PartnerProvider>
        </MarketPlaceProvider>
      </UserProvider>
    </CommonProvider>
  </AuthProvider>
);
