import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import { ExclamationCircleIcon } from 'components/shared/Icons/ExclamationCircleIcon';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { PERIOD } from 'constants/enum';
import { LABELS } from 'constants/onboarding';
import { FormBlock } from 'containers/AddSolution/FormBlock';
import MobileModal from 'containers/PartnerContainer/Mobile';
import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { isOnboardingActive, setOnboardingOptions } from 'tools/onboarding';
import { usePartnerStore } from '../../contexts/PartnerContext';
import {
  endDayFormatted,
  formatPeriodDate,
  queryParamsHandler,
  startDayFormatted,
} from '../PartnerContainer/utils';
import Dashboard from './Dashboard';
import { solutionDetailHints } from './hints';
import classes from './SolutionDetail.module.scss';
import SolutionHeader from './SolutionHeader';
import SolutionTabs from './SolutionTabs';
import { SolutionTabType } from './types';
import { RUSSIAN_LOCALE } from 'utils';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';

const SolutionDetailContainer = observer(() => {
  const { t } = useTranslation();
  const periodOptions = useMemo(
    () => [
      { id: 1, name: 'Day' },
      { id: 2, name: 'Week' },
      { id: 3, name: '14 days' },
      { id: 4, name: 'Month' },
    ],
    [t]
  );
  const {
    fetchSingleServiceAnalytics,
    singleService,
    analytics,
    setCopyOfService,
  } = (usePartnerStore() as any) || {};
  const userStore = useUserStore();
  const navigate = useNavigate();
  const tabs: SolutionTabType[] = [
    {
      id: 'dashboard',
      label: t('Dashboard'),
    },
    {
      id: 'main',
      label: t('Main info'),
    },
    {
      id: 'cases',
      label: t('Cases'),
    },
    {
      id: 'price',
      label: t('Price'),
    },
  ];
  const [solution, setSolution] = useState<any>();
  const { isMediaTablet, isSmallLaptop: isLaptop } = useWindowWidth();
  const [activeTab, setActiveTab] =
    useState<SolutionTabType['id']>('dashboard');
  const [periodFilter, setPeriodFilter] = useState<{
    id: number;
    name: string;
  }>(periodOptions[1]);
  const [openPeriods, setOpenPeriods] = useState(false);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const enableHints =
    isOnboardingActive(userStore?.pagesToOnboard?.solutionsDetail) &&
    dataIsLoaded;
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const handleExit = useCallback(() => {
    setOnboardingOptions({ solutionsDetail: true });
    userStore.enableOnboarding(true);
  }, [userStore.enableOnboarding]);

  const getDatePeriod = () => {
    switch (periodFilter.id) {
      case 1:
        return formatPeriodDate(PERIOD.ONE_DAY);
      case 2:
        return formatPeriodDate(PERIOD.WEEK);
      case 3:
        return formatPeriodDate(PERIOD.TWO_WEEKS);
      case 4:
        return formatPeriodDate(PERIOD.MONTH);
    }
  };

  const getPeriodName = () => {
    switch (periodFilter.id) {
      case 1:
        return t('Compared to last day');
      case 2:
        return t('Compared to last week');
      case 3:
        return t('Compared to last two weeks');
      case 4:
        return t('Compared to last month');
      default:
        return t('Compared to last week');
    }
  };

  const params = useMemo(
    () =>
      queryParamsHandler({
        singleService: true,
        dateFrom: getDatePeriod() || startDayFormatted,
        dateTo: endDayFormatted,
      }),
    [periodFilter]
  );

  useEffect(() => {
    if (singleService?.slug) {
      fetchSingleServiceAnalytics(params, singleService.slug);
    }
  }, [params, singleService]);

  useEffect(() => {
    setSolution({
      ...singleService,
      data: analytics,
    });
  }, [analytics, singleService]);

  useEffect(() => {
    if (singleService?.slug && analytics?.length) {
      setDataIsLoaded(true);
    }
  }, [singleService, analytics]);

  const onCopy = (project: any) => {
    setCopyOfService(singleService);
    navigate('/solutions/add-solution/');
  };

  const stickerColor = () => {
    switch (solution?.status) {
      case 'active':
        return RUSSIAN_LOCALE ? 'green' : 'purple';
      case 'in review':
        return 'yellow';
      case 'archived':
        return 'red';
      default:
        return 'purple';
    }
  };

  const comparePeriod = getPeriodName();

  return (
    <div className={classes.wrapper}>
      <TitleAndDescription pageName='SolutionDetail' title={solution?.name} titleOnDescription/>

      <Steps
        enabled={isLaptop && enableHints}
        steps={solutionDetailHints(t)}
        options={{
          tooltipClass: clsx(
            'customTooltip',
            classes.hintsTooltip,
            activeTab === 'dashboard' && classes['hintsTooltip__top--center']
          ),
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
          autoPosition: false,
        }}
        initialStep={0}
        onComplete={handleExit}
        onBeforeChange={(nextStep) => {
          if (nextStep === 1) {
            setActiveTab('main');
          } else if (nextStep === 0) {
            setActiveTab('dashboard');
          }
        }}
        onExit={handleExit}
      />
      <ul className={classes.breadcrumbs}>
        <li className={classes.breadcrumbs__item}>
          <NavLink to={'/solutions/'} className={classes.breadcrumbs__link}>
            {t('Solutions')}
          </NavLink>
        </li>
        <li className={classes.breadcrumbs__item}>
          <span className={classes.breadcrumbs__link}>{solution?.name}</span>
        </li>
      </ul>

      <SolutionHeader
        title={solution?.name}
        statusText={t(
          solution?.status.charAt(0).toUpperCase() + solution?.status.slice(1)
        )}
        solutionId={solution?.id}
        solutionType={solution?.product}
        stickerColor={stickerColor()}
        isMobile={isMediaTablet}
        t={t}
      />

      <div className={classes['content-wrapper']}>
        <div
          className={clsx(
            classes['hints-block'],
            activeTab !== 'dashboard' && classes['hints-block__shrink']
          )}
        >
          <div className={classes.filters}>
            <SolutionTabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={(tabId) => setActiveTab(tabId)}
            />

            {activeTab === 'dashboard' && (
              <div className={classes.filters__period}>
                <span>{t('Data per')}</span>
                {!isMediaTablet ? (
                  <MultiselectComponent
                    textTheme
                    data={periodOptions.map((option) => ({
                      id: option.id,
                      name: t(option.name),
                    }))}
                    defaultSelected={2}
                    setMultiselect={setPeriodFilter}
                    className={classes.filters__select}
                  />
                ) : (
                  <Button theme="text" onClick={() => setOpenPeriods(true)}>
                    {t(periodFilter.name)}
                    <ArrowDownIcon
                      className={clsx(
                        classes.arrow,
                        openPeriods && classes['arrow--active']
                      )}
                    />
                  </Button>
                )}
              </div>
            )}
          </div>

          {activeTab === 'dashboard' && (
            <>
              <Dashboard
                data={solution?.data}
                filterText={comparePeriod}
                isMobile={isMediaTablet}
                t={t}
              />
              {openPeriods && (
                <div className={classes.modal}>
                  <MobileModal
                    title={t('Select period for data')}
                    data={periodOptions}
                    open={openPeriods}
                    setOpen={setOpenPeriods}
                    select={periodFilter}
                    setSelect={setPeriodFilter}
                  />
                </div>
              )}
            </>
          )}

          {activeTab === 'main' && (
            <>
              <div className={classes.alert}>
                <div className={classes.alert__icon}>
                  <ExclamationCircleIcon />
                </div>
                <div className={classes.alert__message}>
                  <span className={classes.alert__message_title}>
                    {t(
                      'To update the solution info, get in touch with your manager'
                    )}
                  </span>
                  <p className={classes.alert__message_text}>
                    {t(
                      "Updating your service info through the app isn't an option right now, but that's because we're making sure everything stays super accurate and secure for you"
                    )}
                  </p>
                </div>
              </div>
              <FormBlock copiedService={solution} currentStep={1} isDisabled />
            </>
          )}
          {activeTab === 'cases' && (
            <FormBlock copiedService={solution} currentStep={2} isDisabled />
          )}
          {activeTab === 'price' && (
            <FormBlock copiedService={solution} currentStep={3} isDisabled />
          )}
        </div>
      </div>
    </div>
  );
});

export default SolutionDetailContainer;
