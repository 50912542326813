import React from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const PaperClipIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <g id="paperclip">
      <path
        id="Icon"
        d="M17.6271 9.58296L10.1141 17.096C8.40556 18.8045 5.63546 18.8045 3.92692 17.096C2.21837 15.3874 2.21837 12.6173 3.92692 10.9088L11.4399 3.39578C12.579 2.25675 14.4257 2.25675 15.5647 3.39578C16.7037 4.5348 16.7037 6.38154 15.5647 7.52057L8.34633 14.7389C7.77682 15.3085 6.85345 15.3085 6.28394 14.7389C5.71442 14.1694 5.71442 13.2461 6.28394 12.6766L12.6184 6.34205"
        stroke={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
