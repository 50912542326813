export enum PASS_MODE {
  OLD = "oldPass",
  NEW = "newPass",
  CONFIRM = "newPassConfirmation",
}

export enum SORT_BY {
  DATE = "date",
  NAME = "name",
}

export enum COST_TYPE {
  FROM = "COST_TYPE_FROM",
  TO = "COST_TYPE_TO",
}

export enum AMOUNT_UNIT {
  CUR = "$",
  PERC = "%",
  NONE = "",
}

export enum AMOUNT_TYPE {
  MORE = ">",
  LESS = "<",
}

export enum NOTICE {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

export enum DISCOUNT_TYPES {
  PERC = "SALE",
  GIFT = "2+1=2",
}

export enum FILTERS {
  ALL = "all",
  ACTIVE = "is_active",
  IN_REVIEW = "is_review",
  ARCHIVED = "is_archived",
}

export enum FILTERS_LABELS {
  ALL = "all",
  ACTIVE = "active",
  IN_REVIEW = "in review",
  ARCHIVED = "archived",
}

export enum PERIOD {
  TODAY = "TODAY",
  ONE_DAY = "ONE_DAY",
  WEEK = "WEEK",
  TWO_WEEKS = "TWO_WEEKS",
  MONTH = "MONTH",
}