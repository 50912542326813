import { OnboardingPages } from 'types/global';
import { userStore } from '../stores/user-store';

export const setOnboardingOptions = (options: Partial<OnboardingPages>) => {
  const currentStatus = JSON.parse(
    localStorage.getItem('onboardingComplete') || '{}'
  );
  localStorage.setItem(
    'onboardingComplete',
    JSON.stringify({ ...currentStatus, ...options })
  );
};

export const isOnboardingActive = (page: boolean) =>
  process.env.REACT_APP_ONBOARDING === 'true' &&
  !page &&
  userStore?.onboarding_partner_passed === false;
