import clsx from 'clsx';
import { LABELS, solutionsHints } from 'constants/onboarding';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { Button } from 'components/shared/Button';
import UiIcon from 'components/shared/Icon';
import { Pagination } from 'components/shared/Pagination';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { PartnerCard } from './Card';
import EmptyBlock from './EmptyBlock/EmptyBlock';
import { Filters } from './Filters';
import ModerateBlock from './ModerateBlock/ModerateBlock';
import classes from './PartnerContainer.module.scss';
import { PARTNER_COUNTER, usePartnerContainer } from './usePartnerContainer';
import 'intro.js/introjs.css';

export const PartnerContainer = observer(({ title }: { title: string }) => {
  const {
    t,
    services,
    servicesCount,
    clearCopyOfService,
    statusCount,
    enableHints,
    showLastHint,
    handleExit,
    filter,
    setFilter,
    isOnModerate,
    cardInfo,
    currentPage,
    changeChunks,
    setCurrentPage,
    onPaginate,
    goToTop,
  } = usePartnerContainer();
  const { GOT_IT, NEXT, BACK } = LABELS(t);
  const { isSmallLaptop: isLaptop } = useWindowWidth();
  const isEmpty = services?.length === 0;
  const totalPages = servicesCount / PARTNER_COUNTER;
  const activeCount = statusCount.active;
  const inReviewCount = statusCount.in_review;
  const archivedCount = statusCount.archived;

  return (
    <div className={classes.container}>
      <Steps
        enabled={isLaptop && (enableHints || showLastHint)}
        steps={
          showLastHint
            ? solutionsHints(t).slice(-1)
            : solutionsHints(t).slice(0, -1)
        }
        options={{
          tooltipClass: clsx(
            'customTooltip',
            classes.hintsTooltip,
            showLastHint && classes['hintsTooltip__top--center']
          ),
          showBullets: !showLastHint,
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={handleExit}
        onExit={handleExit}
      />

      <TitleAndDescription pageName="Solutions" />

      <div className={classes.title}>
        {title}
        <span>{servicesCount}</span>
      </div>

      <div className={classes.filterBlock}>
        <Filters
          all={servicesCount}
          active={activeCount}
          inReview={inReviewCount}
          archived={archivedCount}
          filter={filter}
          setFilter={setFilter}
        />

        <NavLink to="/solutions/add-solution/">
          <Button
            disabled={isOnModerate}
            theme="primary"
            className={classes.mainButton}
            onClick={clearCopyOfService}
          >
            {(isEmpty || isOnModerate) && (
              <UiIcon name="PlusIcon" additionalClassName={classes.plusIcon} />
            )}
            {t('Add solution')}
          </Button>
        </NavLink>
      </div>

      <ul className={classes.cardBlock}>
        {isOnModerate ? (
          <ModerateBlock />
        ) : isEmpty ? (
          <EmptyBlock />
        ) : (
          cardInfo?.map((card: any, index: number) => (
            <PartnerCard key={index} project={card} />
          ))
        )}

        {!isOnModerate && !isEmpty && (
          <div className={classes.pagination}>
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={changeChunks()?.length}
                setPageNumber={(page: number) => {
                  setCurrentPage(page);
                  onPaginate(page);
                  goToTop();
                }}
                nextPage={() =>
                  currentPage > 0 &&
                  currentPage < totalPages &&
                  setCurrentPage(currentPage + 1)
                }
                prevPage={() =>
                  currentPage !== 0 && setCurrentPage(currentPage - 1)
                }
              />
            )}
          </div>
        )}
      </ul>
    </div>
  );
});
