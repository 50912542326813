import React, { FC } from 'react';
import classes from './NoticeBox.module.scss';
import clsx from 'clsx';
import { NoticeType } from 'types/util-types';
import UiIcon from 'components/shared/Icon';

interface NoticeBoxProps {
  title: string;
  description: string;
  type: NoticeType;
}

export const NoticeBox: FC<NoticeBoxProps> = ({ title, description, type }) => {
  const iconName = () => {
    if (type === 'success') {
      return 'CheckCircleIcon';
    }
    if (type === 'warning') {
      return 'InfoIconAlt';
    }
    if (type === 'error') {
      return 'UnionIcon';
    }
  };

  return (
    <div className={clsx(classes.noticeContainer, classes[type])}>
      <UiIcon name={iconName()} additionalClassName={classes.icon} />
      <div className={classes.textBlock}>
        <div className={classes.noticeTitle}>{title}</div>
        <div className={classes.noticeDescription}>{description}</div>
      </div>
    </div>
  );
};
