import clsx from 'clsx';
import Autocomplete from 'components/common/Autocomplete';
import { Button } from 'components/shared/Button';
import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { Input } from 'components/shared/Input';
import { Link } from 'components/shared/Link';
import MultiSelect from 'components/shared/MultiSelect';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { defaultLanguage } from 'i18n/i18n';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller } from 'react-hook-form';
import classes from './RegistrationFormRu.module.scss';
import { useRegistrationFormRu } from './useRegistrationFormRu';
import { observer } from 'mobx-react-lite';
import { RUSSIAN_LOCALE } from 'utils';
import { useState } from 'react';

export const RegistrationFormRu = observer(() => {
  const {
    formRef,
    handleSubmit,
    onSubmit,
    errors,
    t,
    control,
    selectedLocation,
    locationOptions,
    callbacks,
    selectedProducts,
    onLoadProducts,
    productsOptions,
    isLoadingProducts,
    setRecaptcha,
    recaptchaRef,
    i18n,
    isDisabled,
    setAgree,
  } = useRegistrationFormRu();

  const [openModal, setOpenModal] = useState(false);

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      className={classes.form}
    >
      <div className={classes.headerWrapper}>
        <h3 className={classes.headerTitle}>{t('You are welcome!')}</h3>
        <p className={classes.headerDescription}>
          {t(
            'Tell us about your business - we will offer clients who are interested in your expertise'
          )}
        </p>
      </div>

      <div className={classes['top-fields']}>
        <div className={classes['input-field']}>
          <Controller
            render={({ field }) => (
              <Input
                maxLength={200}
                type={'text'}
                placeholder={t('Company name')}
                value={field.value}
                onChange={field.onChange}
                error={!!errors?.companyName}
                className={classes.register_theme}
              />
            )}
            control={control}
            name="companyName"
          />
          {errors?.companyName && (
            <TooltipContainer
              text={errors?.companyName?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>

        <div className={classes['input-field']}>
          <Controller
            control={control}
            render={({ field }) => (
              <Input
                maxLength={50}
                type={'text'}
                placeholder={t(`Company's website`)}
                className={clsx(classes.email, classes.register_theme)}
                value={field.value}
                error={!!errors?.companyUrl}
                onChange={field.onChange}
              />
            )}
            name="companyUrl"
          />
          {errors?.companyUrl && (
            <TooltipContainer
              text={errors.companyUrl?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>

        <div className={classes['input-field']}>
          <Controller
            render={({ field }) => (
              <Input
                maxLength={254}
                type={'text'}
                placeholder={t('Job title')}
                value={field.value}
                onChange={field.onChange}
                error={!!errors?.jobTitle}
                className={classes.register_theme}
              />
            )}
            control={control}
            name="jobTitle"
          />
          {errors?.jobTitle && (
            <TooltipContainer
              text={errors?.jobTitle?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>

        <div className={clsx(classes['input-field'])}>
          <Controller
            name="location"
            control={control}
            render={() => (
              <Autocomplete
                options={locationOptions}
                selected={
                  selectedLocation && {
                    value: selectedLocation.id,
                    label: selectedLocation.name,
                  }
                }
                onSelect={(id) => callbacks.onSelectLocation(Number(id))}
                placeholder={t(`Country's office`)}
                additionalClass={classes.register_theme}
              />
            )}
          />
          {errors?.location && (
            <TooltipContainer
              text={errors?.location?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill="#F5222D" width="18px" height="18px" />
            </TooltipContainer>
          )}
        </div>
      </div>

      <div
        className={clsx(classes['input-field'], classes['input-field--full'])}
      >
        <Controller
          name="userProducts"
          control={control}
          render={() => (
            <MultiSelect
              isLoading={isLoadingProducts}
              options={productsOptions}
              scrollDebounce={300}
              onScrollBottom={onLoadProducts}
              selected={selectedProducts.map((product) => ({
                value: product.id,
                label: product.name,
              }))}
              onSelect={(id) => callbacks.onSelectProduct(Number(id))}
              onDeleteSelected={(id) =>
                callbacks.onDeleteSelectedProduct(Number(id))
              }
              placeholder={t(`Company's products`)}
              additionalClassName={classes.multiselect}
            />
          )}
        />
        {errors?.userProducts && (
          <TooltipContainer
            text={errors?.userProducts?.message}
            customClasses={'kit-ui-block'}
            position={'top'}
            className={classes.error}
          >
            <InfoIcon fill="#F5222D" width="18px" height="18px" />
          </TooltipContainer>
        )}
      </div>

      <div className={classes.captcha}>
        <Controller
          render={({ field }) => (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTHA_KEY}`}
              onChange={(e: any) => {
                field.onChange(e);
                e && setRecaptcha(e);
              }}
              hl="en"
              size="normal"
            />
          )}
          name="captcha"
          control={control}
        />
      </div>

      <div className={classes['checkbox-block']}>
        <Controller
          render={({ field }) => (
            <>
              <Checkbox
                onChange={(e) => {
                  setAgree(e);
                  field.onChange(e);
                }}
                checked={field.value}
              />
              <div className={classes.text}>
                <span>{t('I agree to KIT Global')} </span>
                {/* <Link
                  className={classes.link}
                  href={
                    RUSSIAN_LOCALE
                      ? process.env.REACT_APP_RU_PARTNER_OFFER_URL || ''
                      : `https://kit.global/${
                          i18n.language !== defaultLanguage
                            ? `${i18n.language}/`
                            : ''
                        }terms/`
                  }
                  target={'_blank'}
                >
                  {t('terms of Service (with)')}
                </Link> */}

                <Link
                  className={classes.link}
                  href={`${process.env.REACT_APP_PRIVACY_POLICY_URL}`}
                  target={'_blank'}
                >
                  {t('privacy Policy (with)')}
                </Link>
                <span>{t('and')}</span>
                <Link
                  className={classes.link}
                  href={
                    RUSSIAN_LOCALE
                      ? process.env.REACT_APP_RU_PARTNER_OFFER_URL || ''
                      : `https://kit.global/${
                          i18n.language !== defaultLanguage
                            ? `${i18n.language}/`
                            : ''
                        }terms/`
                  }
                  target={'_blank'}
                >
                  {t('Affiliate offer')}
                </Link>
              </div>
            </>
          )}
          name="iAgree"
          control={control}
        />
      </div>

      <div className={classes.submit}>
        <Button
          type="submit"
          className={clsx(classes.button, classes['submit-button'])}
          theme="primary"
          disabled={!isDisabled}
          onClick={() => setOpenModal(true)}
        >
          {t('Create account')}
        </Button>
      </div>
    </form>
  );
});
