import { makeAutoObservable } from 'mobx';
import Service from 'services/service';

class MarketPlaceStore {
  serviceTypes = [];
  businessTypes = [];
  services = [];
  count = 0;
  isLoadingServices = false;
  favoriteCount = 0;
  activeHotDeals = [];
  singleService = {};
  dataTimeHotDeals = {
    dayDeals: 0,
    hourDeals: 0,
    minutesDeals: 0,
    featureDeals: false,
    activeDeals: false,
    startDate: new Date(),
    endDate: new Date(),
  };
  sortingParams = {
    savedCurrentPage: 1,
    savedChunkSize: 8,
    savedServiceTypes: [],
    savedBusinessTypes: [],
    isFavoritesPage: false,
    savedBudget: 0,
    savedSortParams: '',
    isFree: false,
    isNew: false,
    isHot: false,
    isSale: false,
    sortSelect: {
      id: '',
      name: '',
    },
  };

  constructor() {
    makeAutoObservable(this);
  }

  async loadServiceTypes() {
    const serviceTypes = await Service.getServiceTypes();
    this.setServiceTypes(serviceTypes?.data?.results);
    return this.serviceTypes;
  }

  setServiceTypes(serviceTypes: any) {
    this.serviceTypes = serviceTypes || [];
  }

  async loadBusinessTypes() {
    const businessTypes = await Service.getBusinessTypes();
    this.setBusinessTypes(businessTypes?.data?.results);
    return this.businessTypes;
  }

  setBusinessTypes(businessTypes: any) {
    this.businessTypes = businessTypes || [];
  }

  async loadTypes() {
    const businessTypesPromise = this.loadBusinessTypes();
    const serviceTypesPromise = this.loadServiceTypes();
    return await Promise.all([businessTypesPromise, serviceTypesPromise]);
  }

  clearStore() {
    this.sortingParams = {
      savedCurrentPage: 1,
      savedChunkSize: 8,
      savedServiceTypes: [],
      savedBusinessTypes: [],
      isFavoritesPage: false,
      savedBudget: 0,
      savedSortParams: '',
      isFree: false,
      isNew: false,
      isHot: false,
      isSale: false,
      sortSelect: {
        id: '',
        name: '',
      },
    };
    this.serviceTypes = [];
    this.businessTypes = [];
    this.services = [];
    this.isLoadingServices = false;
    this.favoriteCount = 0;
    this.activeHotDeals = [];
    this.singleService = {};
    this.dataTimeHotDeals = {
      dayDeals: 0,
      hourDeals: 0,
      minutesDeals: 0,
      featureDeals: false,
      activeDeals: false,
      startDate: new Date(),
      endDate: new Date(),
    };
  }
}

export const marketPlaceStore = new MarketPlaceStore();
