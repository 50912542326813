import { makeAutoObservable, runInAction } from 'mobx';
import Partner from '../services/partner';
import { userStore } from './user-store';
import { ShowNotification } from '../tools/showNotification';
import { ProjectParams } from '../containers/Projects/types';
import { Status } from '../containers/SolutionDetail/types';
import { getLangName } from '../tools/utils';
import i18n from 'i18next';
import { TProject } from '@type/partner';
import { AxiosError } from 'axios';

export class PartnerStore {
  constructor() {
    makeAutoObservable(this);
  }

  services = [];
  servicesCount = 0;
  statusCount = {
    active: 0,
    in_review: 0,
    archived: 0,
  };
  copiedService = {};
  projects = [];
  selectedProject: TProject | null = null;
  projectsCount = 0;
  nextProjects = '';
  prevProjects = '';
  nextServices = '';
  prevServices = '';
  analytics = [];
  singleService = {};
  goals = [];

  fetchPartnerServices = async (params: string) => {
    try {
      const response = await Partner.getServices(params);
      if (response?.status === 200) {
        const {
          results,
          allServices,
          archived_count,
          active_count,
          review_count,
        } = response?.data;
        this.services = results;
        this.servicesCount = allServices;
        this.statusCount = {
          active: active_count,
          in_review: review_count,
          archived: archived_count,
        };
        this.nextServices = response?.data?.next;
        this.prevServices = response?.data?.previous;
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError)
        ShowNotification({
          type: 'error',
          children: error?.message || 'Error fetching services',
        });
    }
  };

  fetchPartnerProjects = async ({
    params,
    nextPage,
    prevPage,
  }: ProjectParams) => {
    try {
      const response = await Partner.getProjects(params, nextPage, prevPage);
      if (response?.status === 200) {
        this.projects = response?.data.results;
        this.projectsCount = response?.data.allProjectsCount;
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError)
        ShowNotification({
          type: 'error',
          children: error?.message || 'Error fetching services',
        });
    }
  };

  fetchPartnerProject = async (projectId: string) => {
    try {
      const response = await Partner.getProject(Number(projectId));
      runInAction(() => {
        this.selectedProject = response.data;
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError)
        ShowNotification({
          type: 'error',
          children: error?.message || 'Error fetching project',
        });
    }
  };

  fetchSingleService = async (serviceSlug: string) => {
    try {
      const response = await Partner.getSingleService(serviceSlug);
      if (response?.status === 200) {
        const serviceData = response?.data;
        const getStatus = ({ review, archived }: Status) => {
          if (archived) {
            return 'archived';
          } else if (review) {
            return 'in review';
          }
          return 'active';
        };
        this.singleService = {
          status: getStatus({
            review: serviceData?.is_review,
            archived: serviceData?.is_archived,
          }),
          name: getLangName(serviceData, 'name', i18n),
          id: serviceData?.id,
          currency: userStore?.user?.currency,
          files: serviceData.files.map((file: any) => ({
            name: file.name,
            link: file?.link,
            size: file?.file_size,
            id: file?.id,
          })),
          description: getLangName(serviceData, 'description', i18n),
          full_description: getLangName(serviceData, 'description', i18n),
          short_description: getLangName(
            serviceData,
            'short_description',
            i18n
          ),
          period_comment: getLangName(serviceData, 'period_comment', i18n),
          product: serviceData?.product,
          stages: serviceData?.service_stage,
          cases: serviceData?.case,
          company_size: serviceData?.company_size,
          business_type: serviceData?.business_type,
          goals: serviceData?.goals,
          cost: serviceData?.cost_with_discounts,
          price_per: serviceData?.price_per,
          is_free: serviceData?.is_free,
          countries: serviceData?.countries,
          slug: serviceData?.slug,
          payment_types: serviceData?.payment_type,
        };
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError)
        ShowNotification({
          type: 'error',
          children: error?.message || 'Error fetching service',
        });
    }
  };

  fetchSingleServiceAnalytics = async (params: string, serviceSlug: string) => {
    try {
      const response = await Partner.getSingleServiceAnalytics(
        params,
        serviceSlug
      );
      if (response?.status === 200) {
        this.analytics = response?.data.results;
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError)
        ShowNotification({
          type: 'error',
          children: error?.message || 'Error fetching service',
        });
    }
  };

  fetchSolutionGoals = async () => {
    const response = await Partner.getSolutionGoals();
    this.goals = response?.data?.results;
  };

  createPartnerSolution = async (data: any) => {
    try {
      const response = await Partner.createSolution(data);
      if (response?.status === 201) {
        ShowNotification({
          type: 'success',
          children: 'Solution created successfully',
        });
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError)
        ShowNotification({
          type: 'error',
          children: error?.message || 'Error creating solution',
        });
    }
  };

  setCopyOfService = (service: any) => {
    this.copiedService = service;
  };

  clearCopyOfService = () => {
    this.copiedService = {};
    this.singleService = {};
  };

  clearStore = () => {
    this.servicesCount = 0;
    this.services = [];
    this.projects = [];
    this.projectsCount = 0;
    this.nextProjects = '';
    this.prevProjects = '';
  };
}

export const partnerStore = new PartnerStore();
