import { useEffect } from 'react';
import classes from './KPIForm.module.scss';
import UiIcon from 'components/shared/Icon';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { KPIStep } from './KPIStep';
import clsx from 'clsx';
import { KPIFormContainerProps } from './KPIFormContainer.props';
import { FormValidationProps } from 'containers/AddSolution/FormBlock/FormBlock.props';

export const KPIFormContainer = ({
  caseNumber,
  initialSteps,
}: KPIFormContainerProps) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValidationProps>();

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `cases.${caseNumber}.kpi`,
  });

  useEffect(() => {
    replace(initialSteps);
  }, [replace]);

  const addStep = (e: any) => {
    e.preventDefault();
    const maxStep = fields.reduce(
      (max, field: any) => Math.max(max, Number(field?.step)) || 1,
      0
    );
    append({
      step: Number(maxStep) + 1,
      title: `KPI ${Number(maxStep) + 1}`,
      name: '',
      value_type: '',
      value: '',
      unit: '',
    });
  };

  const deleteStep = (index: any) => {
    remove(index);
  };

  return (
    <div className={classes.kpiContainer}>
      {fields?.map((field, index) => (
        <Controller
          key={field.id}
          name={`cases.${caseNumber}.kpi`}
          control={control}
          render={({ field: controllerField }) => {
            const errorMessage = errors?.cases?.[caseNumber]?.kpi?.message;

            return (
              <KPIStep
                error={!!errorMessage}
                step={fields[index] as any}
                valueOptions={controllerField.value}
                canDelete={index >= 5}
                index={index}
                caseNumber={caseNumber}
                onChange={(event: any) => {
                  controllerField.onChange(event);
                }}
                onDelete={() => deleteStep(index)}
              />
            );
          }}
        />
      ))}

      {fields?.length < 4 && (
        <div
          className={clsx(
            classes.addButton,
            !!errors?.cases?.[caseNumber]?.kpi?.message &&
              classes.addButton__error
          )}
          onClick={addStep}
        >
          <UiIcon name="PlusIcon" additionalClassName={classes.plusIcon} />
          <span>{t('Add KPI')}</span>
        </div>
      )}

      {!!errors?.cases?.[caseNumber]?.kpi?.message && (
        <span className={classes.errorText}>
          {errors?.cases?.[caseNumber]?.kpi?.message as string}
        </span>
      )}
    </div>
  );
};
