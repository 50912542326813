import React from 'react';
import Icons from '../Icons';

import './Icon.module.scss';
import clsx from 'clsx';

interface UiIconProps {
  name?: string;
  additionalClassName?: string;
  iconProps?: React.SVGProps<SVGSVGElement>;
  onKeyPress?(event: React.KeyboardEvent<HTMLElement>): void;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
}

const UiIcon: React.FC<UiIconProps> = ({
  name = 'EmptyIcon',
  additionalClassName,
  onKeyPress,
  onClick,
  iconProps,
}) => {
  if (!name) {
    return null;
  }

  const getIcon = (name: string): React.FC<React.SVGProps<SVGSVGElement>> => {
    const IconElements = Icons as any;
    return IconElements[name];
  };

  const Icon = getIcon(name);

  const transformIconName = () => {
    const divideByCaps = name?.split(/(?=[A-Z])/);
    return divideByCaps.join('-').toLowerCase();
  };

  if (getIcon(name)) {
    return (
      <div
        className={clsx(
          'global-icon',
          `icon-${transformIconName()}`,
          additionalClassName
        )}
        onKeyDown={onKeyPress}
        onClick={onClick}
        tabIndex={0}
      >
        <Icon />
      </div>
    );
  }

  return null;
};

export default UiIcon;
