export enum ProjectStatusEnum {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  CANCELLED = 'cancelled',
}

export type CurrencyType = {
  char_code: string;
  id: number;
  name: string;
  symbol: string;
  round_until?: any;
};

type ProjectSource = {
  id: number;
  name: string;
};

export type ProjectType = {
  id: string;
  company: string;
  source: ProjectSource;
  cost: number;
  type: string;
  status: string;
  serial: number;
  solutionId: number;
  projectId: number;
  currency: CurrencyType;
};

export type StatusAmount = {
  type: string;
  count: number;
};

export type SolutionType = {
  id: number;
  solution: {
    id: number;
    name: string;
    totalCost: number;
    currency: CurrencyType;
    statusCount: StatusAmount[];
  };
  projects: ProjectType[];
};

export type MockProjectType = {
  id: string;
  company: string;
  solution: {
    id: number;
    name: string;
  };
  status: ProjectStatusEnum;
  source: string;
  cost: number;
  serial: number;
};

export type SolutionTree = {
  id: number;
  name: string;
  totalCost: number;
  currency: CurrencyType;
  projects: Omit<ProjectType, 'solution'>[];
};

export type ProjectParams = {
  params?: string;
  nextPage?: string;
  prevPage?: string;
};
