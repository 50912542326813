import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const ExclamationCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <rect
      width="40"
      height="40"
      rx="20"
      fill="url(#paint0_linear_11178_251603)"
    />
    <path
      d="M18.8171 9.19739L22.5613 9.43674L21.571 23.5062L18.0079 23.2784L18.8171 9.19739ZM17.1098 28.3167C17.148 27.7185 17.4023 27.2186 17.8727 26.817C18.3631 26.4167 18.9305 26.2371 19.5746 26.2783C20.2188 26.3195 20.7586 26.5698 21.1941 27.0293C21.6297 27.4889 21.8283 28.0177 21.7901 28.6158C21.7518 29.214 21.4875 29.7132 20.997 30.1136C20.5065 30.5139 19.9392 30.6934 19.295 30.6523C18.671 30.6124 18.1412 30.3627 17.7057 29.9032C17.2702 29.4436 17.0716 28.9148 17.1098 28.3167Z"
      fill="white"
    />
    <defs>
      {!RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_11178_251603"
          x1="6.25"
          y1="2.91667"
          x2="32.0833"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8362F3" stopOpacity="0.4" />
          <stop offset="1" stopColor="#8362F3" />
        </linearGradient>
      )}
      {RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_11178_251603"
          x1="6.25"
          y1="2.91667"
          x2="32.0833"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F14B4B" stopOpacity="0.4" />
          <stop offset="1" stopColor="#F14B4B" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
