import { yupResolver } from '@hookform/resolvers/yup';
import { useCommonStore } from 'contexts/CommonContext';
import { useMarketPlaceStore } from 'contexts/marketPlaceContext';
import { usePartnerStore } from 'contexts/PartnerContext';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getLangName } from 'tools/utils';
import { RUSSIAN_LOCALE } from 'utils';
import * as yup from 'yup';
import { convertFileToBase64, initialSteps, stepTitles } from '../utils';
import { CaseProps, FormValidationProps } from './FormBlock.props';
import {
  firstStepSchema,
  secondStepSchema,
  thirdStepSchema,
} from './validationSchemas';

export const useFormBlock = (copiedService: any, currentStep?: number) => {
  const { t, i18n } = useTranslation();
  const initialBusinessTypes = useMarketPlaceStore()?.businessTypes;
  const navigate = useNavigate();
  const { goals, createPartnerSolution, singleService } =
    usePartnerStore() as any;
  const { dictionaries, products, platforms: platformData } = useCommonStore();

  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false);
  const [schema, setSchema] = useState<yup.AnyObjectSchema>(firstStepSchema(t));
  const [internalData, setInternalData] = useState<any>(singleService);

  useEffect(() => {
    if (currentStep === 1) {
      setSchema(firstStepSchema(t));
    } else if (currentStep === 2) {
      setSchema(secondStepSchema(t));
    } else if (currentStep === 3) {
      setSchema(thirdStepSchema(t));
    }
  }, [currentStep]);

  useEffect(() => {
    if (copiedService) {
      setInternalData(copiedService);
    }
  }, [copiedService]);

  const industryTypes = initialBusinessTypes?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));

  const companySizes = (externalData: any) =>
    externalData?.company_size?.map((item: any) => ({
      value: item?.type,
      label: getLangName(item, 'name', i18n),
    })) || [];

  const platforms = platformData?.map((item: any) => ({
    value: item.id,
    label: getLangName(item, 'name', i18n),
  }));

  const productTypes = internalData?.productTypes || products;

  const stages = internalData?.stages?.length
    ? internalData?.stages?.map((step: any) => {
        let stepTitle;
        if (internalData?.stages?.length < stepTitles(t).length) {
          stepTitle = `${stepTitles(t)[step?.order - 1]} ${t('step')}`;
        } else {
          stepTitle = `Step ${step?.order + 1}`;
        }
        return {
          step: step?.order,
          value: step?.name,
          title: stepTitle,
        };
      })
    : initialSteps(t);

  const defaultCase = () => {
    if (internalData?.cases?.length) {
      return internalData?.cases?.map((item: any) => ({
        caseName: item?.name,
        caseBusinessType: item?.business_type?.id,
        keyAchievements: getLangName(item, 'achievement', i18n),
        platforms: item?.platform,
        secondStepDescription: getLangName(item, 'description', i18n),
        kpi: item?.case_kpises?.map((kpi: any, index: number) => ({
          title: `KPI ${index + 1}`,
          step: index + 1,
          name: kpi?.description,
          value: kpi?.amount,
          valueType: kpi?.amount_type,
          unit: kpi?.amount_unit,
        })),
      }));
    } else return [];
  };

  const methods = useForm<FormValidationProps>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: internalData?.name || '',
      companySize: internalData?.company_size,
      industry: internalData?.business_type,
      goals: internalData?.goals,
      shortDescription: internalData?.short_description || '',
      fullDescription: internalData?.full_description || '',
      productType: internalData?.product?.id || 0,
      price: internalData?.cost || 0,
      projectDuration: internalData?.period_comment,
      steps: stages,
      cases: defaultCase(),
      currency: RUSSIAN_LOCALE ? 'Российский рубль, ₽' : 'USD',
      'useCases-2': {
        name: internalData?.files?.[0]?.name || '',
        size: internalData?.files?.[0]?.size || 0,
        id: internalData?.files?.[0]?.id,
      },
      is_free: internalData?.is_free || false,
      countries: internalData?.countries,
      payment_type: internalData?.payment_types,
    },
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const values = methods.getValues();
    const fileData = await convertFileToBase64(values?.['useCases-2']);
    const filesData = values?.['useCases-2']?.id
      ? [
          {
            id: values?.['useCases-2']?.id,
          },
        ]
      : [
          {
            name: values?.['useCases-2']?.name,
            body: fileData,
          },
        ];

    const goals = values.goals?.map((item: any) => {
      if (item?.id) {
        return { id: item.id };
      }
      if (!item?.id && item?.name) {
        return { name: item?.name };
      }
    });

    const countries = values.countries?.map((item: any) => item.id);
    const payment_type = values.payment_type?.map((item: any) => item.id);
    const company_size = values.companySize?.map(
      (item: any) => item.id || item.type
    );

    const data = {
      name: values.name,
      product: values.productType,
      company_industry: values.industry?.map((item: any) => item.id),
      company_size,
      goals,
      description: values.fullDescription,
      short_description: values.shortDescription,
      period_comment: values.projectDuration,
      stages: values.steps?.map((step: any) => ({
        name: step.value,
        order: step.step,
      })),
      files: filesData,
      cases: values.cases?.map((item: CaseProps) => ({
        name: item.caseName,
        business_type: item.caseBusinessType,
        achievement: item.keyAchievements,
        platforms: item.platforms?.map((platform: any) => platform.id),
        description: item.secondStepDescription,
        case_kpises: item.kpi?.map((kpi: any) => ({
          description: kpi?.name,
          amount: kpi?.value,
          amount_type: kpi?.valueType,
          amount_unit: kpi?.unit,
        })),
      })),
      price_per: 'BUDGET',
      cost: String(values?.price)?.replace(/\s+/g, ''),
      currency: RUSSIAN_LOCALE ? 'RUB' : 'USD',
      is_free: values.is_free,
      countries,
      payment_type,
    };

    await createPartnerSolution(data).then(() => {
      setOpenSubmitModal(false);
      navigate('/solutions/');
    });
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    navigate('/solutions/');
  };

  return {
    methods,
    productTypes,
    industryTypes,
    dictionaries,
    companySizes,
    internalData,
    goals,
    setOpenCancelModal,
    platforms,
    setOpenSubmitModal,
    openCancelModal,
    openSubmitModal,
    handleSubmit,
    handleCancel,
    t,
  };
};
