import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import UiIcon from 'components/shared/Icon';
import ArrowUpRight from 'components/shared/Icons/ArrowUpRight';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { NavLink } from 'react-router-dom';
import MobileModal from '../Mobile';
import { percentNumber } from '../utils';
import classes from './Card.module.scss';
import { useCard } from './useCard';
import { useState } from 'react';

export const PartnerCard = ({ project }: { project: any }) => {
  const {
    mobileModalDataPrice,
    isTablet,
    tagClass,
    tagText,
    t,
    dropdownRef,
    open,
    setOpen,
    onCopy,
    isNoneImpressions,
    isNoneFavorites,
    isNoneCart,
    percentDifference,
    mobileModalData,
  } = useCard(project);
  
  const [openPriceTag, setOpenPriceTag] = useState(false);
  const [selected, setSelected] = useState<any>(null);
  const { name, link } = project?.source || {};
  const title = project?.name;
  const isLongDesc = project.data?.price?.period?.length > 10;
  const currency = project?.currency?.symbol;

  const separateIcon = (
    <span className={classes.card__top__bottom__separate}></span>
  );

  const desktopModalData = mobileModalDataPrice?.map(
    (item: any, index: number) => (
      <span key={`${item.id}${index}`} className={classes.tooltipItem}>
        {item?.name}
        <span className={classes.price}>{item?.value}</span>
      </span>
    )
  );

  return (
    <div className={classes.card}>
      <div className={classes.card__top}>
        <div className={classes.card__top__left}>
          {isTablet && (
            <div className={clsx(classes.card__tag, tagClass)}>{tagText}</div>
          )}
          <NavLink to={link} className={classes.card__link}>
            <h3 className={classes.card__header}>{project.name}</h3>
          </NavLink>
          <div className={classes.card__description}>
            {!isTablet && (
              <div className={clsx(classes.card__tag, tagClass)}>{tagText}</div>
            )}
            <div className={classes.card__top__bottom}>
              {!isTablet && separateIcon}
              {!isTablet && (
                <span className={classes.card__id}>{t('Type of product')}</span>
              )}
              <span className={classes.card__source__mobile}>{name}</span>
              {project.id && separateIcon}
              {project.id && (
                <div className={classes.card__id}>ID {project.id}</div>
              )}
            </div>
          </div>
        </div>

        <div className={classes.card__top__right} ref={dropdownRef}>
          <Button
            theme="light"
            className={classes.moreButton}
            onClick={() => setOpen(!open)}
          >
            <UiIcon
              name="DotIcon"
              additionalClassName={classes.moreButton__dotIcon}
            />
          </Button>

          {open && !isTablet && (
            <div className={classes.dropdown}>
              <span
                className={classes.dropdown__item}
                onClick={() => onCopy(project)}
              >
                {t('Copy')}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className={classes.card__grid}>
        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>
            {project.data.impressions?.name}
          </div>
          <div className={classes.content}>
            <div className={classes.content__inner}>
              <span className={classes.dataCount}>
                {project.data.impressions?.count}
              </span>
              <div
                className={clsx(
                  classes.percentage,
                  percentNumber({
                    decrease: project.data.impressions?.percentage,
                  }) && classes.down,
                  isNoneImpressions && classes.none
                )}
              >
                {!isNoneImpressions && (
                  <ArrowUpRight className={clsx(classes.arrowIcon)} />
                )}
                {!isNoneImpressions &&
                  percentNumber({
                    percentage: project.data.impressions?.percentage,
                  })}
              </div>
            </div>
            <div className={classes.bottomDescription}>
              {project.data.impressions?.description}
            </div>
          </div>
        </div>

        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>
            {project.data.favorites?.name}
          </div>
          <div className={classes.content}>
            <div className={classes.content__inner}>
              <span className={classes.dataCount}>
                {project.data.favorites?.count}
              </span>
              <div
                className={clsx(
                  classes.percentage,
                  percentNumber({
                    decrease: project.data.favorites?.percentage,
                  }) && classes.down,
                  isNoneFavorites && classes.none
                )}
              >
                {!isNoneFavorites && (
                  <ArrowUpRight className={classes.arrowIcon} />
                )}
                {!isNoneFavorites &&
                  percentNumber({
                    percentage: project.data.favorites?.percentage,
                  })}
              </div>
            </div>
            <div className={classes.bottomDescription}>
              {project.data.favorites?.description}
            </div>
          </div>
        </div>

        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>
            {project.data.cart?.name}
          </div>
          <div className={classes.content}>
            <div className={classes.content__inner}>
              <span className={classes.dataCount}>
                {project.data.cart?.count}
              </span>
              <div
                className={clsx(
                  classes.percentage,
                  percentNumber({ decrease: project.data.cart?.percentage }) &&
                    classes.down,
                  isNoneCart && classes.none
                )}
              >
                {!isNoneCart && (
                  <ArrowUpRight className={clsx(classes.arrowIcon)} />
                )}
                {!isNoneCart &&
                  percentNumber({ percentage: project.data.cart?.percentage })}
              </div>
            </div>
            <div className={classes.bottomDescription}>
              {project.data.cart?.description}
            </div>
          </div>
        </div>

        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>
            <span>{project.data.price?.name}</span>
            {project.data.price?.discountCost && percentDifference > 0 ? (
              <div
                className={classes.priceTag}
                onClick={() => isTablet && setOpenPriceTag(!openPriceTag)}
              >
                {!isTablet ? (
                  <TooltipContainer
                    text={desktopModalData}
                    position="left"
                    classNameTooltip={classes.priceTooltip}
                  >
                    {percentDifference}%
                  </TooltipContainer>
                ) : (
                  <span>{percentDifference}%</span>
                )}
              </div>
            ) : null}
          </div>

          <div className={classes.content}>
            <div className={clsx(classes.content__inner, classes.priceBox)}>
              <span className={classes.dataCount}>
                <FormatPrice
                  currency={currency}
                  price={project.data.price?.discountCost}
                />
              </span>
              {project.data.price?.period && !project.isFree && (
                <div
                  className={clsx(
                    classes.percentage,
                    classes.price,
                    isLongDesc && classes.price__long
                  )}
                >
                  {!isTablet ? '/' : ''} {project.data.price.period}
                </div>
              )}
            </div>

            <div className={classes.bottomDescription}>
              {project.data.price?.description}
            </div>
          </div>
        </div>
      </div>

      {isTablet && openPriceTag && (
        <MobileModal
          open={openPriceTag}
          setOpen={setOpenPriceTag}
          title={title}
          data={mobileModalDataPrice}
          isPriceTag
        />
      )}

      {isTablet && open && (
        <MobileModal
          select={selected}
          setSelect={setSelected}
          open={open}
          setOpen={setOpen}
          title={title}
          data={mobileModalData}
          onSelect={(item: any) => {
            if (item?.id === 2 || item?.id === 3) {
              onCopy(project);
            } else if (item?.id === 1) {
              // onArchive(project?.source?.link)
            }
          }}
        />
      )}
    </div>
  );
};
