import { useAuthStore } from "contexts/AuthContext";
import { useUserStore } from "contexts/UserContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ShowNotification } from "tools/showNotification";
import { setAccessToken, setRefreshToken } from "services/http";

export const useRegistration = () => {
  const [step, setStep] = useState(1);
  const [sendEmail, setSendEmail] = useState("");
  const [successData, setSuccessData] = useState<any>(null);

  const authStore = useAuthStore();
  const userStore = useUserStore();

  const { t } = useTranslation();

  const resendEmail = async () => {
    const result = await authStore.loginByTokens(
      successData?.access,
      successData?.refresh,
    );

    if (result) {
      const user = await userStore.loadUser();

      if (!user?.email_confirmed) {
        await userStore.sendRegistrationCompleteEmail();
        ShowNotification({
          type: "success",
          children: t(
            "Email successfully sent. It may take up to 2 minutes to deliver",
          ),
        });
      } else {
        ShowNotification({
          type: "error",
          children: t("Your email is already confirmed"),
        });
      }
      setAccessToken("");
      setRefreshToken("");
      authStore.setAuth(false);

      return;
    } else {
      ShowNotification({
        type: "error",
        children: t("Something went wrong, please try later"),
      });
    }
  };
  return {
    step,
    setStep,
    t,
    setSendEmail,
    sendEmail,
    setSuccessData,
    resendEmail,
  };
};
