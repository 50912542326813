import React, { useMemo, useState } from 'react';
import { Cell, Column, useTable } from 'react-table';
import { ProjectType, SolutionType } from '../types';
import classes from './ProjectsTable.module.scss';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Accordion from 'components/common/Accordion';
import clsx from 'clsx';
import { Link } from 'components/shared/Link';
import StatusSticker from '../StatusSticker';
import TableAccordionHeader from '../TableAccordionHeader';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';

type ProjectsTableProps = {
  data: SolutionType[];
};

export type StatusAmount = {
  active: number;
  completed: number;
  paused: number;
  cancelled: number;
};

const getTableColumns = function (t: TFunction): Column<ProjectType>[] {
  return [
    {
      Header: '#',
      accessor: 'serial',
    },
    {
      Header: t('Projects'),
      accessor: 'company',
    },
    {
      Header: t('Status'),
      accessor: 'status',
    },
    {
      Header: t('Source link'),
      accessor: 'source',
    },
    {
      Header: t('ID'),
      accessor: 'id',
    },
    {
      Header: t('Project cost'),
      accessor: 'cost',
    },
  ];
};

function ProjectsTable(props: ProjectsTableProps) {
  const { t } = useTranslation();
  const columns = useMemo(() => getTableColumns(t), [t]);
  const projects = props?.data?.map((solution) => solution?.projects)?.flat();

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable<ProjectType>({
      data: projects,
      columns,
    });

  const [openedSolutions, setOpenedSolutions] = useState<number[]>([]);

  const solutionsArray = props?.data?.map((currProject: any) => {
    const statusAmount: StatusAmount = {
      active: 0,
      completed: 0,
      paused: 0,
      cancelled: 0,
    };

    currProject?.projects?.length &&
      currProject?.projects?.forEach((project: any) => {
        switch (project?.status?.toLowerCase()) {
          case 'active':
            statusAmount.active += 1;
            break;
          case 'completed':
            statusAmount.completed += 1;
            break;
          case 'paused':
            statusAmount.paused += 1;
            break;
          case 'cancelled':
            statusAmount.cancelled += 1;
            break;
        }
      });

    return {
      id: currProject?.solution?.id,
      name: currProject?.solution?.name,
      totalCost: currProject?.solution?.totalCost,
      currency: currProject?.solution?.currency,
      statusAmount,
      projectsLength: currProject?.projects?.length,
      projects: currProject?.projects,
    };
  });

  const callbacks = {
    onSolutionClick: (id: number) => {
      setOpenedSolutions((prev) => {
        if (prev?.includes(id)) {
          return prev?.filter((solutionId) => solutionId !== id);
        } else {
          return [...prev, id];
        }
      });
    },
  };

  const renderCellData = function (cell: Cell<ProjectType>) {
    const value = () => {
      if (
        cell?.value === undefined ||
        cell?.value === null ||
        typeof cell?.value === 'object'
      ) {
        return cell?.value?.name;
      } else {
        return cell?.value;
      }
    };
    switch (cell?.column?.id) {
      case 'company':
        return (
          <Link
            href={`/projects/${cell?.row?.original?.projectId}`}
            className={classes['text-cell']}
          >
            {value()}
          </Link>
        );
      case 'id':
        return <span className={classes['text-cell']}>{value()}</span>;
      case 'cost':
        return (
          <FormatPrice
            currency={cell?.row?.original?.currency?.symbol}
            price={value()}
          />
        );
      case 'source':
        const source =
          value() &&
          (value()?.includes('http') ? value() : `https://${value()}`);
        return (
          <Link className={classes['link-cell']} href={source}>
            {value()}
          </Link>
        );
      case 'status':
        return <StatusSticker status={value()} t={t} />;
      default:
        return cell.render('Cell');
    }
  };

  // const loadMoreProjects = () => {
  //   setVisibleProjects((prev) => prev + 10);
  // };

  return (
    <div {...getTableProps()}>
      {headerGroups?.map((headerGroup, headerIndex) => (
        <div
          {...headerGroup?.getHeaderGroupProps()}
          key={`${headerIndex}`}
          className={clsx(classes.table__row, classes.table__row_header)}
        >
          {headerGroup?.headers?.map((column, columnIndex) => (
            <div
              {...column?.getHeaderProps()}
              key={columnIndex}
              className={classes.table__cell}
            >
              {column?.render('Header')}
            </div>
          ))}
        </div>
      ))}
      <div {...getTableBodyProps()} className={classes.body}>
        {solutionsArray?.map((solution) => {
          if (!solution?.projectsLength) {
            return null;
          } else {
            return (
              <Accordion
                expanded={openedSolutions?.includes(solution?.id)}
                hideArrowIcon
                key={solution?.id}
                scrollable={solution?.projectsLength > 10}
                header={
                  <TableAccordionHeader
                    t={t}
                    title={solution?.name}
                    open={openedSolutions?.includes(solution?.id)}
                    setOpen={() => callbacks.onSolutionClick(solution?.id)}
                    totalCost={solution?.totalCost}
                    statusAmount={solution?.statusAmount}
                    projects={solution?.projects}
                    currency={solution?.currency}
                  />
                }
              >
                {rows
                  ?.filter((row) => row?.original?.solutionId === solution?.id)
                  ?.map((filteredRow, rowIndex) => {
                    prepareRow(filteredRow);
                    return (
                      <div
                        {...filteredRow?.getRowProps()}
                        key={`${solution?.id}-${rowIndex}`}
                        className={classes.table__row}
                      >
                        {filteredRow?.cells?.map((cell, cellIndex) => (
                          <div
                            {...cell?.getCellProps()}
                            key={`${solution?.id}-${rowIndex}-${cellIndex}`}
                            className={classes.table__cell}
                          >
                            {cell?.column?.id === 'serial' ? (
                              <span>{rowIndex + 1}</span>
                            ) : (
                              renderCellData(cell)
                            )}
                          </div>
                        ))}
                      </div>
                    );
                  })}
                {/*{rows.filter((row) => row.original?.solutionId === solution.id).length > visibleProjects && (
              <Button
                onClick={loadMoreProjects}
                theme="light"
                className={classes.button}
              >
                {t("See more")}
              </Button>
            )}*/}
              </Accordion>
            );
          }
        })}
      </div>
    </div>
  );
}

export default ProjectsTable;
