import clsx from 'classnames';
import { Button } from 'components/shared/Button';
import { PurpleArrowLeftIcon } from 'components/shared/Icons/PurpleArrowLeftIcon';
import MultiSelect from 'components/shared/MultiSelect';
import { NoticeBox } from 'components/shared/NoticeBox';
import { NOTICE } from 'constants/enum';
import { addSolutionsThirdStepHints } from 'constants/onboarding';
import { Steps } from 'intro.js-react';
import { Controller } from 'react-hook-form';
import Input from '../../Input';
import Switcher from '../../Switcher';
import { multiFieldValues } from '../../utils';
import classes from '../FormBlock.module.scss';
import { ThirdStepProps } from './ThirdStep.props';
import { useThirdStep } from './useThirdStep';

const ThirdStep = ({
  disabled,
  setOpenSubmitModal,
  setOpenCancelModal,
  setCurrentStep,
}: ThirdStepProps) => {
  const {
    isLaptop,
    enableHints,
    t,
    GOT_IT,
    NEXT,
    BACK,
    handleExit,
    isFree,
    errors,
    endIconOptions,
    control,
    internalPaymentOptions,
    selectedPaymentTypes,
    isMediaTablet,
    callbacks,
    internalCountryOptions,
    selectedCountries,
    paymentTypeOptions,
    setInternalPaymentOptions,
    countriesOptions,
    setInternalCountryOptions,
    onValidateClick,
  } = useThirdStep(setOpenSubmitModal, disabled);

  return (
    <div className={clsx(classes.thirdStep, disabled && classes.disabled)}>
      <Steps
        enabled={isLaptop && enableHints}
        steps={addSolutionsThirdStepHints(t)}
        options={{
          tooltipClass: clsx('customTooltip', classes.hintsTooltip),
          showBullets: false,
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={handleExit}
        onExit={handleExit}
      />

      <div className={classes.noticeBlock}>
        <NoticeBox
          type={NOTICE.WARNING}
          title={t('Please enter the minimum budget for your paid solution')}
          description={t(
            'This is the starting price needed to kick off the project. Our base currency is USD, prices are automatically converted to local currencies for Clients'
          )}
        />
      </div>

      <div className={classes.hintBlock}>
        <div className={classes.checkboxBLock}>
          <Controller
            render={({ field }) => (
              <Switcher
                isOn={field.value}
                setIsOn={() => {
                  field.onChange(!field.value);
                }}
                label={t('Free solution')}
              />
            )}
            name="is_free"
          />
        </div>

        <div className={classes.priceBlock}>
          <div className={clsx(classes.price, isFree && classes.disabledPrice)}>
            <Controller
              render={({ field }) => (
                <Input
                  placeholder={t('Price')}
                  // value={Math.round(Number(field.value)) || "0"}
                  value={field.value}
                  onChange={field.onChange}
                  title={t('Price')}
                  error={errors?.price?.message}
                  maxLength={16}
                  endIcon={endIconOptions(
                    t(
                      'Enter the solution price. Enter the minimum budget price for the service you are offering. This price is the base amount necessary to begin the project.'
                    )
                  )}
                  inputForCurrency={true}
                />
              )}
              name="price"
              control={control}
            />
          </div>

          <div className={classes.currencyBlock}>
            <div
              className={clsx(classes.currencyBlock__input, classes.disabled)}
            >
              <Controller
                render={({ field }) => (
                  <Input
                    value={field.value}
                    disabled
                    onChange={(value) => field.onChange(value)}
                    placeholder={t('Currency')}
                    title={t('Currency')}
                    error={errors?.currency?.message}
                    endIcon={endIconOptions(
                      t('Enter your price. Up to 16 symbols')
                    )}
                  />
                )}
                name="currency"
                control={control}
              />
            </div>
          </div>
        </div>

        <div className={classes.paymentBlock}>
          <div className={clsx(classes.currencyBlock__select)}>
            <Controller
              render={({ field }) => (
                <MultiSelect
                  selected={multiFieldValues(selectedPaymentTypes, field, true)}
                  options={internalPaymentOptions}
                  isSolutionForm
                  title={t('Payment type')}
                  placeholder=""
                  endIcon={endIconOptions(
                    t(
                      'Select the appropriate payment types. This is crucial for different stages and situations.'
                    )
                  )}
                  additionalClassName={classes.multiselectWrapper}
                  onSelect={(id) => {
                    callbacks.onSelectPaymentType(id, field.onChange);
                  }}
                  isSearch
                  multiline
                  contentHeight="50px"
                  contentWidth={isMediaTablet ? '103%' : 'unset'}
                  showCounter
                  searchPlaceholder={t('Search')}
                  onDeleteSelected={(id) => {
                    if (id === 'all') field.onChange(null);
                    callbacks.onDeletePaymentType(id, field.onChange);
                    paymentTypeOptions?.length === 1 && field.onChange(null);
                  }}
                  onSearch={(value) => {
                    paymentTypeOptions &&
                      setInternalPaymentOptions(
                        paymentTypeOptions?.filter((item) =>
                          String(item?.label)
                            ?.toLowerCase()
                            ?.includes(value.toLowerCase())
                        )
                      );
                  }}
                  error={errors?.payment_type?.message}
                />
              )}
              name="payment_type"
              control={control}
            />
          </div>
        </div>

        <div className={classes.countriesBlock}>
          <Controller
            render={({ field }) => (
              <MultiSelect
                selected={multiFieldValues(selectedCountries, field, true)}
                options={internalCountryOptions}
                isSolutionForm
                title={t('Country')}
                placeholder=""
                endIcon={endIconOptions(
                  t(
                    'Please enter the countries where you plan to offer your services. This helps us tailor and display your offerings to clients in relevant regions, expanding your market reach'
                  )
                )}
                additionalClassName={classes.multiselectWrapper}
                onSelect={(id) => {
                  callbacks.onSelectCountry(id, field.onChange);
                }}
                isSearch
                multiline
                contentHeight="50px"
                contentWidth={isMediaTablet ? '103%' : 'unset'}
                showCounter
                searchPlaceholder={t('Search')}
                onDeleteSelected={(id) => {
                  if (id === 'all') field.onChange(null);
                  callbacks.onDeleteCountry(id, field.onChange);
                  countriesOptions.length === 1 && field.onChange(null);
                }}
                onSearch={(value) => {
                  setInternalCountryOptions(
                    countriesOptions?.filter((item) =>
                      String(item?.label)
                        ?.toLowerCase()
                        ?.includes(value.toLowerCase())
                    )
                  );
                }}
                error={errors?.countries?.message}
              />
            )}
            name="countries"
            control={control}
          />
        </div>
      </div>

      {!disabled && (
        <div className={classes.bottomBlock}>
          <div className={classes.left}>
            <Button
              className={classes.button}
              theme="light"
              onClick={(e: any) => {
                e.preventDefault();
                setCurrentStep?.(2);
              }}
            >
              <PurpleArrowLeftIcon />
              {t('Back')}
            </Button>

            <Button
              className={classes.button}
              theme="light"
              onClick={(e: any) => {
                e.preventDefault();
                setOpenCancelModal(true);
              }}
            >
              {t('Cancel')}
            </Button>
          </div>

          <Button
            className={classes.button}
            theme="default"
            onClick={async (e: any) => {
              e.preventDefault();
              await onValidateClick();
            }}
          >
            {t('Submit to review')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ThirdStep;
